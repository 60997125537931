import React, { ReactElement } from 'react'
import { Box, Theme } from '@mui/material'
import { Link as GatsbyLink } from 'gatsby'

import Copy from '@components/core/text/Copy'
import Headline from '@components/core/text/Headline'
import Button from '@components/core/input/Button'
import Image from '@components/core/media/Image'

export type InfoCardProps = {
  headline: string
  copy?: MCDC.Props.RichText
  linkTo?: MCDC.Props.ILinkTo
  image?: MCDC.Props.IAssetImage
  sx?: MCDC.Props.IDefault['sx']
}

export default function InfoCard({
  headline,
  copy,
  image,
  linkTo,
  sx,
}: InfoCardProps): ReactElement {
  return (
    <Box
      sx={[
        {
          position: 'relative',
          borderRadius: 2,
          backgroundColor: 'common.white',
          overflow: 'hidden',
        },
        sx,
      ]}
    >
      {linkTo && (
        <Box
          component={GatsbyLink}
          to={linkTo.url}
          sx={[
            {
              position: 'absolute',
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              display: 'block',
              zIndex: 1,
            },
            sx,
          ]}
        />
      )}

      <Box
        sx={(theme: Theme) => ({
          position: 'relative',
          [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'row-reverse',
          },
        })}
      >
        {image && (
          <Box
            sx={(theme: Theme) => ({
              [theme.breakpoints.up('md')]: { width: '40%' },
            })}
          >
            <Image
              {...image}
              objectFit="cover"
              objectPosition="center"
              scaleToFit
              sx={{
                height: '100%',
                width: '100%',
              }}
            />
          </Box>
        )}
        <Box
          sx={(theme: Theme) => ({
            p: 6,
            [theme.breakpoints.up('md')]: { width: '60%', p: 12 },
          })}
        >
          <Headline variant="h5" sx={{ mb: 4 }}>
            {headline}
          </Headline>
          <Copy sx={{ mb: 4 }} component="div">
            {copy}
          </Copy>
          {linkTo && (
            <Button
              to={linkTo.url}
              isExternal={linkTo.isExternal}
              color="primary"
              sx={(theme: Theme) => ({
                pointerEvents: 'auto',
                mt: 4,
                [theme.breakpoints.down('md')]: {
                  width: '100%',
                },
              })}
            >
              {linkTo.label}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}
