import React, { ComponentType, CSSProperties, ReactElement } from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { Box, Theme } from '@mui/material'

export type ImageProps = {
  data: IGatsbyImageData
  ratios?: MCDC.Props.BreakpointNumbers
  alt?: string
  description?: string
  copyright?: string
  objectFit?: CSSProperties['objectFit']
  objectPosition?: CSSProperties['objectPosition']
  position?: 'static' | 'relative'
  imgStyle?: React.CSSProperties
  backgroundColor?: 'string'
  loadingType?: 'eager' | 'lazy'
  scaleToFit?: boolean
  component?: ComponentType
  sx?: MCDC.Props.IDefault['sx']
}

/**
 * Image
 */
export default function Image({
  data,
  ratios = {},
  alt,
  description = '',
  position = 'relative',
  objectFit,
  objectPosition,
  backgroundColor,
  component,
  imgStyle,
  loadingType = 'lazy',
  scaleToFit = false,
  sx,
}: ImageProps): ReactElement | null {
  if (!data || !data.images) return null
  const additionalProps = {
    ...(component && {
      component,
    }),
  }

  return (
    <Box
      {...additionalProps}
      sx={[
        (theme: Theme) => ({
          position,
          overflow: 'hidden',
          width: '100%',
          '& picture, & picture:last-of-type img': {
            height: 'inherit',
            width: 'inherit',
          },
          '.gatsby-image-wrapper': {
            minWidth: 'inherit',
            minHeight: 'inherit',
          },
          '.gatsby-image-wrapper > div:first-of-type': {
            [theme.breakpoints.down('md')]: ratios.sm
              ? {
                  pt: `${ratios.sm * 100}% !important`,
                }
              : undefined,
            [theme.breakpoints.down('lg')]: ratios.md
              ? {
                  pt: `${ratios.md * 100}% !important`,
                }
              : undefined,
          },
          '[data-placeholder-image]': {
            objectFit: objectFit,
            objectPosition: objectPosition,
          },
        }),
        sx,
      ]}
    >
      <GatsbyImage
        image={data}
        alt={alt || description}
        loading={loadingType}
        objectFit={objectFit}
        objectPosition={objectPosition}
        backgroundColor={backgroundColor}
        style={
          scaleToFit ? { width: '100%', height: '100%' } : { height: 'inherit' }
        }
        imgStyle={
          imgStyle || {
            height: '100%',
            width: '100%',
          }
        }
      />
    </Box>
  )
}
