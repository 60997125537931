import React, { ReactElement } from 'react'
import { Box, Theme } from '@mui/material'
import Button from '@components/core/input/Button'
import LinkedInIcon from '@static/images/icons/linkedin.svg'

export type SocialShareItemProps = {
  icon: string
  link: string
  embedCode: string
  label: string
  sx?: MCDC.Props.IDefault['sx']
}

export default function SocialShareItem({
  link,
  embedCode,
  label,
}: SocialShareItemProps): ReactElement {
  return (
    <Box
      sx={(theme: Theme) => ({
        overflow: 'hidden',
        backgroundColor: 'white',
        py: 8,
        px: 2,
        borderRadius: 2,
        [theme.breakpoints.down('md')]: {
          px: '0',
        },
      })}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          pb: 4,
        }}
      >
        <LinkedInIcon style={{ marginRight: '30px' }} />
      </Box>
      <Box sx={{ overflow: 'hidden' }}>
        <iframe
          src={embedCode}
          frameBorder="0"
          allowFullScreen={false}
          title="Eingebetteter Beitrag"
          style={{
            scrollbarWidth: 'none',
            overflow: 'hidden',
            height: '346px',
            width: '104%',
          }}
        ></iframe>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          to={link}
          isExternal={true}
          color="primary"
          sx={(theme: Theme) => ({
            pointerEvents: 'auto',
            mt: 4,
            [theme.breakpoints.down('md')]: {
              width: '100%',
            },
          })}
        >
          {label}
        </Button>
      </Box>
    </Box>
  )
}
