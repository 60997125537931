/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { ReactNode, ReactElement } from 'react'

export interface IStaticContentfulContext {
  getGlobalFaqs: (topics: MCDC.Props.IGlobalTopic[]) => MCDC.Props.IGlobalFaq[]
  getFormStepPage: (step: number) => MCDC.Contentful.IPageFormStep[] | undefined
  getFormStepPages: () => MCDC.Contentful.IPageFormStep[]
}

export const StaticContentfulContext =
  React.createContext<IStaticContentfulContext>({
    getGlobalFaqs: () => [],
    getFormStepPage: () => undefined,
    getFormStepPages: () => [],
  })

export type StaticContentfulProviderProps = {
  children: ReactNode
  globalFaqs?: MCDC.Props.IGlobalFaq[]
  formStepPages?: MCDC.Contentful.IPageFormStep[]
}

export default function StaticContentfulProvider({
  children,
  globalFaqs = [],
  formStepPages = [],
}: StaticContentfulProviderProps): ReactElement {
  function getGlobalFaqs(topics: MCDC.Props.IGlobalTopic[] = []) {
    return globalFaqs.filter((entry: MCDC.Props.IGlobalFaq) =>
      entry.topics.some((entryTopic) =>
        topics.find((topic) => topic.identifier === entryTopic.identifier)
      )
    )
  }

  function getFormStepPage(step: number) {
    return formStepPages.filter(
      (entry: MCDC.Contentful.IPageFormStep) =>
        parseInt(String(entry.step)) === step
    )
  }

  function getFormStepPages() {
    return formStepPages
  }

  return (
    <StaticContentfulContext.Provider
      value={{
        getFormStepPage,
        getFormStepPages,
        getGlobalFaqs,
      }}
    >
      {children}
    </StaticContentfulContext.Provider>
  )
}
