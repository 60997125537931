import React, { ReactElement } from 'react'
import { Box } from '@mui/material'
import { useIntl } from 'react-intl'

import Popup from '@components/core/container/Popup'
import Headline from '@components/core/text/Headline'
import Copy from '@components/core/text/Copy'
import Button from '@components/core/input/Button'

export type DialogExitOutProps = {
  onClose?: () => void
  onClick: () => void
  sx?: MCDC.Props.IDefault['sx']
}

export default function DialogExitOut({
  onClick,
  onClose,
  sx,
}: DialogExitOutProps): ReactElement {
  const intl = useIntl()

  return (
    <Popup sx={sx} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Headline variant="h5" sx={{ mb: 4 }}>
          {intl.messages['dialog.exitOut.headline']}
        </Headline>
        <Copy variant="small" sx={{ mb: 4 }}>
          {intl.messages['dialog.exitOut.copy']}
        </Copy>
        <Button
          color="primary"
          onClick={() => {
            if (typeof onClose === 'function') {
              onClose()
            }
            onClick()
          }}
        >
          {intl.messages['dialog.exitOut.label'] as string}
        </Button>
      </Box>
    </Popup>
  )
}
