import React, { useEffect, useRef, useState } from 'react'
import { WidgetInstance } from 'friendly-challenge'
import { Box } from '@mui/material'
import { kCaptureKey } from '@utils/constants'

export type FriendlyCaptchaProps = {
  id: string
  isError?: boolean
  value?: string
  onSuccess: (solution: string) => void
  onError: (e: unknown) => void
  sx?: MCDC.Props.IDefault['sx']
}

const FriendlyCaptcha = ({
  id,
  value,
  isError,
  onSuccess,
  onError,
  sx,
}: FriendlyCaptchaProps) => {
  const container = useRef<HTMLDivElement>(null)
  const [currentValue, setCurrentValue] = useState()
  const widget = useRef<any>()

  useEffect(() => {
    if (!widget.current && container.current) {
      widget.current = new WidgetInstance(container.current, {
        startMode: 'auto',
        puzzleEndpoint: 'https://eu-api.friendlycaptcha.eu/api/v1/puzzle',
        doneCallback: onSuccess,
        errorCallback: onError,
      })
    }
    return () => {
      if (widget.current != undefined) widget.current.reset()
    }
  }, [container])

  useEffect(() => {
    if (!!!value && !!currentValue) {
      widget.current.reset()
    }
    setCurrentValue(value)
  }, [value, currentValue])

  return (
    <Box
      sx={[
        sx,
        {
          display: 'inline-block',
          border: '2px solid',
          borderRadius: '8px',
          borderColor: isError ? 'error.main' : 'common.white',
          backgroundColor: 'common.white',
          overflow: 'hidden',
        },
      ]}
    >
      <div
        id={id}
        ref={container}
        className="frc-captcha"
        data-sitekey={kCaptureKey}
        style={{ border: 'none' }}
      />
    </Box>
  )
}

export default FriendlyCaptcha
