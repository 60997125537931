import React, { ReactElement, useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material'
import { FileUploader as FileUploaderComponent } from 'react-drag-drop-files'
import { FormattedMessage, useIntl } from 'react-intl'

import Copy from '@components/core/text/Copy'
import Button from '@components/core/input/Button'
import Icon from '@components/core/media/Icon'
import FileUploaderItem from './components/FileUploaderItem'
import { api } from '../../../utils/api'
import { AxiosResponse } from 'axios'

export type FileUploaderProps = MCDC.Props.IDefault & {
  id: string
  isError: boolean
  initialUploads: MCDC.API.UploadFile[]
  onChange?: (value: MCDC.API.UploadFile[]) => void
}

export default function FileUploader({
  id,
  initialUploads = [],
  isError,
  onChange,
  sx,
}: FileUploaderProps): ReactElement {
  const intl = useIntl()
  const [files, setFiles] = useState<File[]>([])
  const [uploads, setUploads] = useState<MCDC.API.UploadFile[]>(initialUploads)
  const [currentUpload, setCurrentUpload] = useState<MCDC.API.UploadFile>()
  const [removedUpload, setRemovedUpload] = useState<MCDC.API.UploadFile>()

  function changeHandler(value: FileList) {
    const filesToAdd: File[] = []
    for (let i = 0; i < value.length; i++) {
      const entry = value.item(i)
      if (entry) {
        filesToAdd.push(entry)
      }
    }
    const newFiles = [...files, ...filesToAdd.filter((entry) => !!entry)]
    setFiles(newFiles)
  }

  useEffect(() => {
    const registeredUploads = uploads.filter((entry) => !!entry.token)
    if (initialUploads.length !== registeredUploads.length && onChange) {
      onChange(registeredUploads)
    }
  }, [uploads])

  useEffect(() => {
    let isSubcribed = true
    function submit() {
      files
        .filter(
          (file) => !uploads.find((uploadFile) => uploadFile.name === file.name)
        )
        .map(async (file) => {
          const uploadFile = {
            name: file.name,
            size: file.size,
            path: file.webkitRelativePath,
            progress: 0,
            isError: false,
          }
          setCurrentUpload(uploadFile)
          setUploads((prev) => [...prev, uploadFile])
          const response = await api.upload(file, (ev) => {
            setCurrentUpload({
              ...uploadFile,
              progress: (ev.loaded / ev.total) * 100,
            })
          })

          if (response.status !== 200) {
            const violations = (
              response.data as MCDC.API.UploadResponseErrorData
            )?.violations
            setCurrentUpload({
              ...uploadFile,
              progress: 100,
              isError: true,
              message: violations
                ? (intl.messages[
                    `validation.${violations[0].message}`
                  ] as string)
                : undefined,
            })
            return
          }
          const data: MCDC.API.UploadResponseData = (
            response as AxiosResponse<MCDC.API.UploadResponseData, any>
          ).data
          if (isSubcribed) {
            setUploads((prev) => [
              ...prev.filter((prevFile) => prevFile.name !== uploadFile.name),
              { ...uploadFile, token: data.token, progress: 100 },
            ])
            setCurrentUpload(undefined)
          }
        })
    }

    if (files.length > 0) {
      submit()
    }
    return () => {
      isSubcribed = false
    }
  }, [files])

  useEffect(() => {
    let isSubcribed = true
    if (!removedUpload) return

    function remove() {
      if (!removedUpload) return
      setFiles((prev) => {
        return prev.filter((file) => file.name !== removedUpload.name)
      })
      setUploads((prev) => {
        return prev.filter((file) => file.name !== removedUpload.name)
      })
      setRemovedUpload(undefined)
      setCurrentUpload(undefined)
    }

    async function submit() {
      if (!removedUpload) return
      const response = await api.delete(removedUpload.token || '')
      if (!isSubcribed || response.status !== 200) return
      remove()
    }

    if (!removedUpload.isError) {
      submit()
    } else {
      remove()
    }

    return () => {
      isSubcribed = false
    }
  }, [removedUpload])

  const isBusy = !!currentUpload || !!removedUpload

  return (
    <Box id={id} sx={sx}>
      <FileUploaderComponent
        name={id}
        handleChange={changeHandler}
        types={['pdf', 'png', 'jpeg', 'jpg']}
        multiple
        fileOrFiles={files}
        disabled={isBusy}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'common.white',
            borderRadius: '8px',
            border: '2px solid transparent',
            borderColor: isError ? 'error.main' : undefined,
            p: 6,
          }}
        >
          <Icon
            variant="backup"
            sx={{ fontSize: '45px', color: 'primary.main' }}
          />
          <Copy sx={{ mb: 4, color: 'grey.400' }}>
            <FormattedMessage id="label.fileDrag" />
          </Copy>
          <Button color="primary">
            <FormattedMessage id="label.fileSelect" />
          </Button>
        </Box>
      </FileUploaderComponent>
      <Stack>
        {uploads.map((entry, index) => {
          const isUploading = entry.name === currentUpload?.name
          const item = isUploading && currentUpload ? currentUpload : entry
          return (
            <FileUploaderItem
              name={item.name}
              size={item.size}
              isError={item.isError}
              message={
                !isUploading
                  ? (intl.messages['label.uploaded'] as string)
                  : item.isError
                  ? item.message ||
                    (intl.messages['validation.document.size'] as string)
                  : undefined
              }
              progress={isUploading ? item.progress : entry.progress}
              onRemove={() =>
                (!isBusy || (isUploading && item.isError)) &&
                setRemovedUpload(item)
              }
              sx={{ mt: 4 }}
              key={index}
            />
          )
        })}
      </Stack>
    </Box>
  )
}
