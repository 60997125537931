import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { AppBar, Toolbar } from '@mui/material'
import { HistoryLocation } from '@gatsbyjs/reach-router'

import Navigation from '@components/modules/Navigation'
import { motion, useViewportScroll } from 'framer-motion'
import { appBarMotionProps } from '@utils/motion'
import NavigationDrawer from '@components/modules/Navigation/components/NavigationDrawer'
import useGlobalState from '@components/site/hooks/useGlobalState'
import { FormStepper } from '@components/modules/FormStepper'
import useForms from '@components/site/hooks/useForms'
import { navigate } from 'gatsby'
import clsx from 'clsx'

export type HeaderProps = MCDC.Props.IDefault & {
  navItems: MCDC.Props.ILinkTo[]
  navMetaItems: MCDC.Props.ILinkTo[]
  formStepItems: MCDC.Contentful.IPageFormStep[]
  location: HistoryLocation
}

export default function Header({
  navItems,
  navMetaItems,
  formStepItems = [],
  location,
}: HeaderProps): ReactElement {
  const { activeDrawer } = useGlobalState()
  const [appAniVariant, setAppAniVariant] = useState<'hide' | 'show'>('show')
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const { currentStep, stepsComplete, isFormPage } = useForms()

  const scroll = useViewportScroll()

  const stepperItems: MCDC.Props.IOption[] = useMemo(
    () =>
      formStepItems.map(
        (entry) =>
          ({ label: entry.label, value: entry.step } as MCDC.Props.IOption)
      ),
    [formStepItems]
  )

  useEffect(() => {
    if (isFormPage) {
      if (appAniVariant === 'hide') setAppAniVariant('show')
      return
    }

    let prevScrollY = scroll.scrollY.get()
    scroll.scrollY.onChange((v) => {
      let variant: 'hide' | 'show' = 'show'
      const scrollY = scroll.scrollY.get()
      if (scrollY > 80 && scrollY > prevScrollY) {
        variant = 'hide'
      }
      prevScrollY = scroll.scrollY.get()
      if (appAniVariant !== variant) {
        setAppAniVariant(variant)
      }
    })

    return () => scroll.scrollY.clearListeners()
  }, [scroll, appAniVariant])

  const itemHome = navItems[0]
  const items = navItems.slice(1)
  const activeNavItemIndex = items.findIndex(
    (entry) =>
      (entry.url !== '/' && location?.pathname.includes(entry.url)) ||
      location?.pathname === entry.url
  )

  return (
    <AppBar
      className={clsx({
        'is-formPage': isFormPage,
      })}
      component={motion.header}
      animate={appAniVariant}
      {...appBarMotionProps}
      sx={{ position: 'fixed' }}
    >
      <Toolbar
        sx={{ height: 'inherit', px: '0 !important', flexDirection: 'column' }}
      >
        <Navigation
          itemHome={itemHome}
          itemForm={navMetaItems[0]}
          items={items}
          activeIndex={activeNavItemIndex}
          onToggle={() =>
            activeDrawer
              ? activeDrawer.onClose()
              : setIsDrawerOpen(!isDrawerOpen)
          }
          isDisabled={isFormPage}
          isOpen={isDrawerOpen || !!activeDrawer}
        />
        {isFormPage && (
          <FormStepper
            items={stepperItems}
            step={currentStep}
            stepsComplete={stepsComplete}
            onSelect={(item) => {
              const jobStepConfig = formStepItems.find(
                (entry) => entry.step === item.value
              )
              if (jobStepConfig) {
                navigate(jobStepConfig.fields.linkTo.url)
              }
            }}
          />
        )}
        <NavigationDrawer
          items={items}
          metaItems={navMetaItems}
          activeIndex={activeNavItemIndex}
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          sx={{ mt: 16 }}
        />
      </Toolbar>
    </AppBar>
  )
}
