import React, { ForwardedRef, forwardRef, ReactElement, useState } from 'react'

import { IconButton, InputAdornment, TextField } from '@mui/material'
import Icon, { IconProps } from '@components/core/media/Icon'
import clsx from 'clsx'

export type TextInputProps = {
  id: string
  label?: string
  placeholder?: string
  type?: 'text' | 'date' | 'password' | 'email' | 'search'
  icon?: IconProps['variant']
  defaultValue?: string
  helperText?: string
  multiline?: boolean
  rows?: number
  minRows?: number
  maxRows?: number
  isRequired?: boolean
  isSuccess?: boolean
  isError?: boolean
  isDisabled?: boolean
  withoutBorder?: boolean
  withoutClearField?: boolean
  onChange: (value: string) => void
  sx?: MCDC.Props.IDefault['sx']
  inputSx?: MCDC.Props.IDefault['sx']
}

const TextInput = forwardRef(function TextInput(
  {
    label,
    placeholder,
    id,
    type,
    icon,
    multiline,
    rows,
    defaultValue = '',
    helperText = '',
    minRows,
    maxRows,
    isError,
    isSuccess,
    isRequired,
    isDisabled,
    withoutBorder,
    withoutClearField,
    onChange,
    sx,
    inputSx,
  }: TextInputProps,
  ref: ForwardedRef<HTMLDivElement> | undefined
): ReactElement {
  const [isFocused, setIsFocused] = useState(false)
  const [value, setValue] = useState(defaultValue || '')

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    if (onChange) {
      onChange(e.target.value)
    }
  }

  const clearHandler = (e: React.MouseEvent) => {
    e.preventDefault()
    setValue('')
    if (onChange) {
      onChange('')
    }
  }

  function rendeStartAdornment() {
    const styleSX = { width: '24px', fontWeight: 700 }
    if (type === 'search') {
      return (
        <InputAdornment position="start" sx={styleSX}>
          <Icon variant="search" size="medium" />
        </InputAdornment>
      )
    }

    if (!icon) return null
    return (
      <InputAdornment position="start" sx={styleSX}>
        <Icon variant={icon} size="medium" />
      </InputAdornment>
    )
  }

  function renderEndAdornment() {
    if (!value || withoutClearField || !isFocused) return

    return (
      <InputAdornment position="end" sx={{ mr: 1 }}>
        <IconButton
          aria-label="field clear"
          onMouseDown={clearHandler}
          edge="end"
          color="inherit"
        >
          <Icon variant="close" size="medium" />
        </IconButton>
      </InputAdornment>
    )
  }

  return (
    <TextField
      ref={ref}
      variant="filled"
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={changeHandler}
      data-testid="input"
      id={id}
      type={type}
      multiline={multiline}
      InputProps={{
        startAdornment: rendeStartAdornment(),
        endAdornment: renderEndAdornment(),
        sx: inputSx,
        className: clsx({ 'MuiInputBase-noBorder': withoutBorder }),
        onFocus: () => setIsFocused(true),

        onBlurCapture: () => setIsFocused(false),
      }}
      color={isSuccess ? 'success' : undefined}
      error={isError}
      helperText={helperText}
      disabled={isDisabled}
      rows={rows}
      minRows={minRows}
      maxRows={maxRows}
      fullWidth
      sx={sx}
    />
  )
})

export default TextInput
