import React, { ReactElement } from 'react'
import Section from '@components/core/container/Section'
import Box from '@mui/material/Box'
import { Breakpoint, Theme } from '@mui/material'

import Headline from '@components/core/text/Headline'
import Button from '@components/core/input/Button'
import Copy from '@components/core/text/Copy'

export type TeaserTextProps = {
  headline?: string
  copy: MCDC.Props.RichText
  linkTo?: MCDC.Props.ILinkTo
  alignment?: 'left' | 'center'
  maxWidth?: Breakpoint
  sx?: MCDC.Props.IDefault['sx']
}

export default function TeaserText({
  headline,
  copy,
  linkTo,
  alignment = 'left',
  maxWidth,
}: TeaserTextProps): ReactElement {
  return (
    <Section
      // theme="light"
      maxWidth={maxWidth}
      // paddingSize="small"
      sx={(theme: Theme) => ({
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]:
          alignment === 'center'
            ? {
                alignItems: 'center',
                textAlign: 'center',
              }
            : undefined,
      })}
    >
      {headline && (
        <Headline variant="h3" sx={{ mb: linkTo ? 2 : 6 }}>
          {headline}
        </Headline>
      )}
      <Copy component="div" variant="body1">
        {copy}
      </Copy>
      {linkTo && (
        <Box sx={{ mt: 6 }}>
          <Button
            to={linkTo.url}
            isExternal={linkTo.isExternal}
            color="primary"
            variant="rounded"
            sx={(theme: Theme) => ({
              [theme.breakpoints.down('md')]: {
                width: '100%',
              },
            })}
          >
            {linkTo.label}
          </Button>
        </Box>
      )}
    </Section>
  )
}
