import React, { ReactElement, SyntheticEvent } from 'react'
import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material'
import clsx from 'clsx'

export type CheckboxProps = {
  id?: string
  name: string
  label: string
  value?: string
  isDisabled?: boolean
  isChecked?: boolean
  isDefaultChecked?: boolean
  isError?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  sx?: MCDC.Props.IDefault['sx']
}

export default function Checkbox({
  id,
  name,
  label,
  value,
  isChecked,
  isDefaultChecked,
  isDisabled,
  isError,
  onChange,
  sx,
}: CheckboxProps): ReactElement {
  return (
    <FormControlLabel
      sx={sx}
      className={clsx({ 'Mui-error': isError })}
      control={
        <MuiCheckbox
          className={clsx({ 'Mui-error': isError })}
          defaultChecked={isDefaultChecked}
          size="medium"
        />
      }
      id={id}
      name={name}
      label={label}
      value={value}
      checked={isChecked}
      onChange={
        onChange as (
          e: SyntheticEvent<Element, Event>,
          checked: boolean
        ) => void
      }
      disabled={isDisabled}
    />
  )
}
