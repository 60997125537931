/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { graphql, useStaticQuery } from 'gatsby'

const useContentfulNavigation = () => {
  const { locale } = useIntl()
  const { main } = useStaticQuery(
    graphql`
      query {
        main: allContentfulNavigation(
          filter: { identifier: { eq: "navigationMain" } }
        ) {
          nodes {
            ...NavigationFragment
          }
        }
      }
    `
  )

  const results = useMemo(() => {
    const { homePage, formPage, mainPages, metaPages } = main.nodes.find(
      (entry: MCDC.Contentful.INavigation) => entry.node_locale === locale
    )

    return {
      homePage: {
        ...homePage.fields?.linkTo,
      },
      formPage: {
        ...formPage.fields?.linkTo,
      },
      mainPages: mainPages.map((entry: any) => entry.fields?.linkTo),
      metaPages:
        metaPages?.map((entry: any) => ({
          ...entry.fields?.linkTo,
        })) || [],
    }
  }, [main, locale])

  return results
}
export default useContentfulNavigation
