import React, { CSSProperties, ReactElement, useEffect, useState } from 'react'
import {
  Box,
  Breakpoint,
  Container,
  styled,
  Theme,
  useMediaQuery,
} from '@mui/material'
import _ReactPlayer, { ReactPlayerProps } from 'react-player/lazy'
import clsx from 'clsx'

import Image from '@components/core/media/Image'
import { StyledMotionDiv } from '@utils/motion'
import DripSvg from '@static/images/icons/drip.svg'

const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>

export type SectionProps = {
  theme?: MCDC.Props.ThemeType
  background?: MCDC.Props.IGlobalImage | MCDC.Props.IGlobalVideo
  backgroundPosition?: CSSProperties['objectPosition']
  backgroundLoading?: 'eager' | 'lazy'
  paddingTop?: MCDC.Props.BreakpointFlags
  paddingBottom?: MCDC.Props.BreakpointFlags
  paddingSize?: 'default' | 'small' | 'medium' | 'large'
  maxWidth?: boolean | Breakpoint
  minHeight?: number | MCDC.Props.BreakpointNumbers
  withDrip?: boolean
  withoutThemeClass?: boolean
  sx?: MCDC.Props.IDefault['sx']
  className?: MCDC.Props.IDefault['className']
  children?: MCDC.Props.IDefault['children']
}

function getThemeStyles(
  muiTheme: Theme,
  theme?: MCDC.Props.ThemeType,
  withBackground?: boolean
) {
  switch (theme) {
    case 'light':
      return {
        backgroundColor: !withBackground
          ? muiTheme.palette.background.light
          : undefined,
      }
    case 'medium':
      return {
        backgroundColor: !withBackground
          ? muiTheme.palette.background.medium
          : undefined,
      }
    case 'dark':
      return {
        color: muiTheme.palette.text.inverted,
        backgroundColor: !withBackground
          ? muiTheme.palette.background.dark
          : undefined,
      }
    case 'primary':
      return {
        color: muiTheme.palette.text.primary,
        backgroundColor: !withBackground
          ? muiTheme.palette.primary.main
          : undefined,
      }
    case 'secondary':
      return {
        color: muiTheme.palette.text.inverted,
        backgroundColor: !withBackground
          ? muiTheme.palette.background.secondary
          : undefined,
      }
    default:
      return {}
  }
}

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'moduleTheme' && prop !== 'withBackground',
})<{
  withBackground?: boolean
  moduleTheme?: MCDC.Props.ThemeType
}>(({ theme, moduleTheme, withBackground }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  ...getThemeStyles(theme, moduleTheme, withBackground),
  maxWidth: theme.spacing(theme.maxWidth('xl')),
  marginLeft: 'auto',
  marginRight: 'auto',
}))

const Section = React.forwardRef(function Section(
  {
    theme,
    className,
    children,
    background,
    paddingTop,
    paddingBottom,
    paddingSize = 'large',
    maxWidth = true,
    minHeight,
    backgroundPosition,
    backgroundLoading,
    withDrip,
    withoutThemeClass,
    sx,
  }: SectionProps,
  ref
): ReactElement {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  const [videoSrc, setVideoSrc] = useState<string>()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const image =
    (background as MCDC.Props.IGlobalVideo)?.image ||
    (background as MCDC.Props.IGlobalImage)
  const video =
    !!(background as MCDC.Props.IGlobalVideo)?.playlist &&
    (background as MCDC.Props.IGlobalVideo)

  const withBackground = !!background || !!theme
  const withAutoPaddingTop = paddingTop === undefined && withBackground
  const withAutoPaddingBottom = paddingBottom === undefined && withBackground

  const minHeights: MCDC.Props.BreakpointNumbers =
    typeof minHeight === 'object'
      ? { sm: minHeight.sm, md: minHeight.md, lg: minHeight.lg }
      : { sm: minHeight }

  useEffect(() => {
    if (!video) return
    setVideoSrc(
      isMobile ? video.playlistMobile || video.playlist : video.playlist
    )
  }, [isMobile])

  return (
    <Wrapper
      ref={ref}
      className={clsx([
        !withoutThemeClass && withBackground && 'Mui-themed',
        'Section',
        className,
      ])}
      moduleTheme={theme}
      withBackground={!!background}
      sx={sx}
    >
      {background && (
        <StyledMotionDiv
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
          transition={{ duration: 0.25 }}
          sx={[
            video && {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            },
            (muiTheme: Theme) => ({
              '&:after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                top: '50%',
                left: 0,
                right: 0,

                background:
                  theme === 'dark'
                    ? 'linear-gradient(180deg, rgba(45,45,45,0) 0%, rgba(45,45,45,0.4) 35%, rgba(45,45,45,0.7) 100%)'
                    : 'linear-gradient(180deg, rgba(247,247,247,0) 0%, rgba(247,247,247,0.4) 35%, rgba(247,247,247,0.7) 100%)',
                // [muiTheme.breakpoints.up('md')]: {
                //   display: 'none',
                // },
              },
            }),
          ]}
        >
          {image && (
            <Image
              {...image}
              objectFit="cover"
              objectPosition={backgroundPosition}
              loadingType={backgroundLoading}
              sx={{
                ...(video
                  ? { position: 'absolute', left: 0, top: 0, width: '100%' }
                  : {}),
                height: '100%',
                minHeight: 'inherit',
                minWidth: '100%',
                pointerEvents: 'none',
                transition: 'opacity 500ms ease 500ms',
                opacity: isVideoPlaying ? 0 : 1,
              }}
            />
          )}
          {videoSrc && (
            <ReactPlayer
              config={{
                file: {
                  forceVideo: true,
                  attributes: {
                    playsInline: true,
                    style: {
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top center',
                    },
                  },
                },
              }}
              url={videoSrc}
              muted
              loop
              playing
              onError={(e) => console.warn('Video Error:', e)}
              onPlay={() => setIsVideoPlaying(true)}
              width="100%"
              height="100%"
            />
          )}
        </StyledMotionDiv>
      )}
      <Box
        sx={[
          (theme: Theme) => ({
            width: '100%',
            display: 'flex',
            ...(minHeight
              ? {
                  minHeight: minHeights.sm ? `${minHeights.sm}px` : undefined,
                  [theme.breakpoints.up('md')]: minHeights.md
                    ? {
                        minHeight: `${minHeights.md}px`,
                      }
                    : undefined,
                  [theme.breakpoints.up('lg')]: minHeights.lg
                    ? {
                        minHeight: `${minHeights.lg}px`,
                      }
                    : undefined,
                }
              : { height: '100%' }),
          }),
          background ? { position: 'absolute', top: 0, left: 0 } : {},
        ]}
      >
        <Container
          maxWidth={maxWidth === true ? 'lg' : maxWidth}
          sx={(muiTheme: Theme) => ({
            px: maxWidth === false ? '0 !important' : undefined,
            display: 'flex',
            flexDirection: 'column',
            ...muiTheme.mixins.containerPadding(
              withAutoPaddingTop || paddingTop || false,
              withAutoPaddingBottom || paddingBottom || false,
              paddingSize
            ),
          })}
        >
          {children}
        </Container>
      </Box>
      {withDrip && (
        <Box
          sx={(theme: Theme) => ({
            position: 'absolute',
            bottom: '-32px',
            right: '75px',
            zIndex: 1,
            [theme.breakpoints.up('lg')]: {
              right: '270px',
            },
          })}
        >
          <DripSvg />
        </Box>
      )}
    </Wrapper>
  )
})

export default Section
