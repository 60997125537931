import React from 'react'
import { Container, List, Theme } from '@mui/material'
import { FormStepperItem } from './components'

export type FormStepperProps = {
  items: MCDC.Props.IOption[]
  step: number
  stepsComplete: number
  onSelect: (item: MCDC.Props.IOption) => void
  sx?: MCDC.Props.IDefault['sx']
}

export function FormStepper({
  items,
  step,
  stepsComplete,
  onSelect,
  sx,
}: FormStepperProps) {
  return (
    <Container
      maxWidth="md"
      sx={[
        sx,
        (theme: Theme) => ({ [theme.breakpoints.down('sm')]: { px: 0 } }),
      ]}
    >
      <List
        sx={[
          {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            width: '100%',
            p: 0,
          },
        ]}
      >
        {items.map((entry, index) => (
          <FormStepperItem
            label={entry.label}
            isSelected={index + 1 === step}
            isDisabled={index + 1 > stepsComplete}
            onClick={() => {
              onSelect(entry)
            }}
            key={entry.value}
          />
        ))}
      </List>
    </Container>
  )
}
