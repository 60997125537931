const unit = 4
const gutters = {
  xs: 6,
  sm: 6,
  md: 6,
  lg: 14,
  xl: 14,
}
const padding = {
  default: {
    sm: 8,
    md: 8,
    lg: 12,
  },
  small: {
    sm: 8,
    md: 12,
    lg: 20,
  },
  medium: {
    sm: 16,
    md: 16,
    lg: 32,
  },
  large: {
    sm: 16,
    md: 20,
    lg: 30,
  },
}

const Spacing = {
  unit,
  gutters,
  padding,
  maxWidth: {
    xs: 900 / unit + gutters.xs * 2,
    sm: 900 / unit + gutters.sm * 2,
    md: 1080 / unit + gutters.md * 2,
    lg: 1280 / unit + gutters.lg * 2,
    xl: 1920 / unit + gutters.lg * 2,
  },
}

export default Spacing
