import React, { ReactElement } from 'react'
import { Helmet } from 'react-helmet'

export type MetaTagsProps = {
  meta: Partial<MCDC.Contentful.IPageContext['page']['metaTags']> & {
    canonical?: string
    noIndex?: boolean
  }
  locale?: string
}

export default function MetaTags({
  meta,
  locale,
}: MetaTagsProps): ReactElement {
  return (
    <Helmet htmlAttributes={{ lang: locale }}>
      {meta?.metaTitle && <title>{meta.metaTitle}</title>}

      {meta?.metaDescription && (
        <meta name="description" content={meta.metaDescription} />
      )}
      {meta?.metaOgTitle && (
        <meta property="og:title" content={meta.metaOgTitle} />
      )}
      <meta name="twitter:card" content="summary" />
      {meta?.metaOgDescription && (
        <meta property="og:description" content={meta.metaOgDescription} />
      )}
      {meta?.metaOgImage && (
        <meta property="og:image" content={meta.metaOgImage} />
      )}
      {meta?.canonical && <link rel="canonical" href={meta.canonical} />}
      {meta?.noIndex && <meta name="robots" content="noindex" />}
    </Helmet>
  )
}
