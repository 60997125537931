import { Components, Theme } from '@mui/material'

export const LinearProgressOverrides = (
  theme: Theme
): Components['MuiLinearProgress'] => ({
  styleOverrides: {
    root: {
      height: '13px',
      borderRadius: '24px',
    },
  },
})
