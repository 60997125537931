export enum kPageMetaType {
  'faq' = 'faq',
}

export const kCaptureKey = process.env.GATSBY_CAPTURE_KEY

//-------------------------------------------------------------
// Cookie Settings
//-------------------------------------------------------------

//General Settings
export const kCookieExpiringDate = 30
export const kIsSecureCookie = true

//Cookie Methods
export const kCookieMethodSessionStorage = 'SessionStorage'
export const kCookieMethodLocalStorage = 'LocalStorage'
export const kCookieMethodCookie = 'Cookie'

//-------------------------------------------------------------
// Cookie Constants
//-------------------------------------------------------------

// functionality menuitems
export const kImageUpload = false
export const kFileUpload = true
export const kShareLocation = false

//-------------------------------------------------------------
// Event Tracking
//-------------------------------------------------------------

export const kEventCategory = 'application_process'
export const kEventCategoryInteraction = 'user_interaction'
export const kEventActionFastApplication = 'link_click_fast_application'

//-------------------------------------------------------------
// Consent Constants
//-------------------------------------------------------------

export const kConsentUpdateTypeCategory = 'Category'
