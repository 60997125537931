import React, { ReactElement, SyntheticEvent } from 'react'
import { Radio as MuiRadio, FormControlLabel } from '@mui/material'
import clsx from 'clsx'

export type RadioProps = MCDC.Props.IDefault & {
  id?: string
  name: string
  label: string
  value: string | undefined
  isDisabled?: boolean
  isChecked?: boolean
  isDefaultChecked?: boolean
  isError: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

export default function Radio({
  id,
  name,
  label,
  value,
  isChecked,
  isDefaultChecked,
  isDisabled,
  isError,
  onChange,
  sx,
}: RadioProps): ReactElement {
  return (
    <FormControlLabel
      sx={sx}
      className={clsx({ 'Mui-error': isError })}
      control={
        <MuiRadio
          className={clsx({ 'Mui-error': isError })}
          name={name}
          defaultChecked={isDefaultChecked}
        />
      }
      id={id}
      name={name}
      label={label}
      value={value}
      disabled={isDisabled}
      onChange={
        onChange as (
          e: SyntheticEvent<Element, Event>,
          checked: boolean
        ) => void
      }
      checked={isChecked}
    />
  )
}
