import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react'
import { Box, SelectChangeEvent } from '@mui/material'

import useForms from '@components/site/hooks/useForms'
import { FormsFieldProps } from '@components/site/providers/FormsProvider'

import { useIntl } from 'react-intl'
import SelectInput from '@components/core/form/fields/SelectInput'
import Radio from '@components/core/form/fields/Radio'
import Checkbox from '@components/core/form/fields/Checkbox'
import TextInput from '@components/core/form/fields/TextInput'
import RadioGroup from '@components/core/form/groups/RadioGroup'
import CheckboxGroup from '@components/core/form/groups/CheckboxGroup'
import EmploymentGroup from '@components/core/form/groups/EmploymentGroup'
import DatePicker from '@components/core/form/fields/DatePicker'
import FileUploader from '../FileUploader'
import FriendlyCaptcha from '@components/core/form/groups/FriendlyCaptcha'

export const createNewEmployment = (): MCDC.Props.IEmployment => ({
  id: Date.now().toString(),
  period_from: undefined,
  period_to: undefined,
  company: undefined,
  position: undefined,
})

const getDefaultValue = (type: FormFieldType) => {
  switch (type) {
    case 'employmentGroups':
      return [createNewEmployment()]
    case 'textArray':
      return ['']
    case 'checkbox':
      return false
    default:
      return undefined
  }
}

export type FormFieldType =
  | 'text'
  | 'textArray'
  | 'select'
  | 'radio'
  | 'radioGroup'
  | 'checkbox'
  | 'checkboxGroup'
  | 'employmentGroups'
  | 'datePicker'
  | 'documents'
  | 'capture'

export type FormFieldProps = {
  id: string
  stepId?: string
  valueKey: string
  type: FormFieldType
  props?: any
  isRequired?: boolean
  isConditional?: boolean
  sx?: MCDC.Props.IDefault['sx']
}

export type FieldProps = FormsFieldProps & {
  type: FormFieldType
  props: any
  sx?: MCDC.Props.IDefault['sx']
}

export type FieldTypeProps = {
  label?: string
  placeholder?: string
  helperText?: string
  isDisabled?: boolean
}

export type FieldTextProps = FieldTypeProps & {
  label?: MCDC.Props.IDefault['children']
  type?: 'text' | 'email'
  multiline?: boolean
  rows?: number
  onChange?: (value: string) => void
}

export type FieldDocumentsProps = FieldTypeProps & {}

export type FieldSelectProps = FieldTypeProps & {
  options: MCDC.Props.IOption[]
  onChange?: (value: MCDC.Props.IOption[]) => void
}

export type FieldRadioProps = FieldTypeProps & {
  onChange?: (value: boolean) => void
}

export type FieldRadioGroupProps = FieldTypeProps & {
  options: MCDC.Props.IOption[]
  onChange?: (value: MCDC.Props.IOption[]) => void
}

export type FieldCheckboxProps = FieldTypeProps & {
  onChange?: (value: boolean) => void
}

export type FieldCheckboxGroupProps = FieldTypeProps & {
  options: MCDC.Props.IOption[]
  onChange?: (value: string[]) => void
}

export type FieldEmplomentGroupProps = FieldTypeProps & {
  value?: MCDC.Props.IEmployment[]
  onChange?: (value: MCDC.Props.IEmployment[]) => void
}

const Field = ({
  id,
  stepId,
  type,
  value,
  isError,
  isRequired,
  errorCode,
  props = {},
  sx,
}: FieldProps) => {
  const { updateField } = useForms()
  const intl = useIntl()
  const fieldLabel =
    props.label && !isRequired
      ? `${props.label} ${intl.messages['label.optional']}`
      : props.label
  const fieldPlaceholder =
    props.placeholder && !isRequired
      ? `${props.placeholder} ${intl.messages['label.optional']}`
      : props.placeholder

  switch (type) {
    case 'select':
      return (
        <SelectInput
          id={id}
          {...props}
          label={fieldLabel}
          placeholder={fieldPlaceholder}
          isError={isError}
          isRequired={isRequired}
          errorText={
            isError && errorCode
              ? (intl.messages[errorCode] as string)
              : undefined
          }
          value={props.options.find(
            (entry: MCDC.Props.IOption) => entry.value === value
          )}
          onChange={(e: SelectChangeEvent<string>) => {
            if (typeof props.onChange === 'function') {
              props.onChange(e.target.value)
            }
            updateField({
              stepId,
              id,
              value: e.target.value,
              isError: !e.target.value,
            })
          }}
          withoutBorder
          sx={sx}
        />
      )

    case 'radio':
      return (
        <Radio
          id={id}
          name={id}
          {...props}
          label={fieldLabel}
          placeholder={fieldPlaceholder}
          isChecked={!!value}
          isError={isError}
          onChange={(e: React.ChangeEvent<HTMLInputElement>, isChecked) => {
            if (typeof props.onChange === 'function') {
              props.onChange(isChecked)
            }
            updateField({
              stepId,
              id,
              value: isChecked,
              isError: isRequired && !isChecked,
            })
          }}
          sx={sx}
        />
      )

    case 'radioGroup':
      return (
        <RadioGroup
          id={id}
          {...props}
          label={fieldLabel}
          placeholder={fieldPlaceholder}
          value={value !== undefined ? value : null}
          isError={isError}
          onChange={(e: SyntheticEvent<Element, Event>, value: any) => {
            if (typeof props.onChange === 'function') {
              props.onChange(value)
            }
            updateField({
              stepId,
              id,
              value,
              isError: isRequired && value === undefined,
            })
          }}
          sx={sx}
        />
      )
    case 'checkbox':
      return (
        <Checkbox
          id={id}
          {...props}
          label={fieldLabel}
          placeholder={fieldPlaceholder}
          isChecked={!!value}
          isError={isError}
          onChange={(e: React.ChangeEvent<HTMLInputElement>, isChecked) => {
            if (typeof props.onChange === 'function') {
              props.onChange(isChecked)
            }
            updateField({
              stepId,
              id,
              value: isChecked,
              isError: isRequired && !isChecked,
            })
          }}
          sx={sx}
        />
      )
    case 'checkboxGroup':
      return (
        <CheckboxGroup
          id={id}
          {...props}
          label={fieldLabel}
          placeholder={fieldPlaceholder}
          defaultValue={value}
          isError={isError}
          onChange={(
            e: SyntheticEvent<Element, Event>,
            value: MCDC.Props.IOption[]
          ) => {
            if (typeof props.onChange === 'function') {
              props.onChange(value)
            }
            updateField({
              stepId,
              id,
              value,
              isError: isRequired && !value,
            })
          }}
          sx={sx}
        />
      )
    case 'datePicker':
      return (
        <DatePicker
          id={id}
          {...props}
          label={fieldLabel}
          placeholder={fieldPlaceholder}
          isError={isError}
          isRequired={isRequired}
          onChange={(fieldValue: string) => {
            updateField({
              stepId,
              id,
              value: fieldValue,
              isError: isRequired && !fieldValue,
            })
          }}
          defaultValue={value}
        />
      )
    case 'documents':
      return (
        <FileUploader
          id={id}
          {...props}
          isError={isError}
          onChange={(fieldValue: MCDC.API.UploadFile[]) => {
            updateField({
              stepId,
              id,
              value: fieldValue,
              isError:
                isRequired &&
                (fieldValue.length <= 0 ||
                  fieldValue.some((entry) => entry.isError)),
            })
          }}
          initialUploads={value}
        />
      )
    case 'capture':
      return (
        <FriendlyCaptcha
          id={id}
          isError={isError}
          value={value}
          onSuccess={(solution: string) => {
            updateField({
              stepId,
              id,
              value: solution,
              isError: !!!solution,
            })
          }}
          onError={(e: unknown) => {
            updateField({
              stepId,
              id,
              value: undefined,
              isError: true,
            })
          }}
        />
      )
    case 'employmentGroups':
      return (
        <Box id={id} sx={sx}>
          {value?.map((entry: MCDC.Props.IEmployment, index: number) => (
            <EmploymentGroup
              id={`employmentGroup${entry.id}`}
              {...props}
              labels={{
                ...props.labels,
                title: `${index + 1}. ${props.labels.title}`,
              }}
              defaultValue={entry}
              isError={isError}
              isRequired={isRequired}
              onDelete={
                value?.length > 1
                  ? (valueGroup: MCDC.Props.IEmployment) => {
                      const values =
                        value?.filter(
                          (entry: MCDC.Props.IEmployment) =>
                            entry.id !== valueGroup.id
                        ) || []
                      if (typeof props.onChange === 'function') {
                        props.onChange(values)
                      }
                      updateField({
                        stepId,
                        id,
                        value: values,
                        // isError: props.isRequired && !value,
                      })
                    }
                  : undefined
              }
              onChange={(valueGroup: MCDC.Props.IEmployment) => {
                const values = !value
                  ? [valueGroup]
                  : value.map((entry: MCDC.Props.IEmployment) =>
                      entry.id === valueGroup.id ? valueGroup : entry
                    )
                if (typeof props.onChange === 'function') {
                  props.onChange(values)
                }

                updateField({
                  stepId,
                  id,
                  value: values,
                  isError: props.isRequired && !value,
                })
              }}
              sx={index > 0 ? { mt: 12 } : undefined}
              key={index}
            />
          ))}
        </Box>
      )
    case 'textArray':
      return (
        <Box id={id} sx={sx}>
          {value?.map((entry: string, index: number) => (
            <TextInput
              id={`${id}-${index}`}
              {...props}
              placeholder={intl.formatMessage(
                { id: props.placeholder },
                { value: index + 1 }
              )}
              defaultValue={entry}
              isError={isError && !!!entry}
              isRequired={isRequired}
              errorText={
                isError && !!!entry && errorCode
                  ? (intl.messages[errorCode] as string)
                  : undefined
              }
              onChange={(inputValue: string) => {
                const newValue = value.map(
                  (entryValue: string, entryIndex: number) =>
                    entryIndex === index ? inputValue : entryValue
                )
                if (typeof props.onChange === 'function') {
                  props.onChange(newValue)
                }
                updateField({
                  stepId,
                  id,
                  value: newValue,
                  isError:
                    isRequired && newValue.filter((entry: string) => !!!entry),
                })
              }}
              sx={index > 0 ? { mt: 6 } : undefined}
              key={index}
            />
          ))}
        </Box>
      )
    default:
      return (
        <TextInput
          id={id}
          {...props}
          label={fieldLabel}
          placeholder={fieldPlaceholder}
          defaultValue={value}
          isError={isError}
          isRequired={isRequired}
          errorText={
            isError && errorCode
              ? (intl.messages[errorCode] as string)
              : undefined
          }
          onChange={(value: string) => {
            if (typeof props.onChange === 'function') {
              props.onChange(value)
            }
            updateField({
              stepId,
              id,
              value: value,
              isError: isRequired && !!!value,
            })
          }}
          sx={sx}
        />
      )
  }
}

export default function FormField({
  id,
  stepId,
  valueKey,
  type,
  props,
  isRequired,
  isConditional,
  sx,
}: FormFieldProps): ReactElement | null {
  const { registerField, unregisterField, updateField, fields } = useForms()
  const [config, setConfig] = useState(fields[id])

  useEffect(() => {
    if (config && config.isRequired !== isRequired) {
      updateField({ ...fields[id], isRequired })
    }
  }, [config, isRequired, updateField])

  useEffect(() => {
    if (fields[id] !== config) {
      setConfig(fields[id])
    }
  }, [fields, id])

  useEffect(() => {
    registerField({
      id,
      key: valueKey,
      stepId,
      isRequired,
      value: getDefaultValue(type),
    })
    return () => {
      if (!isConditional) return
      unregisterField({ id, key: valueKey, stepId })
    }
  }, [])

  return <Field type={type} {...(config || {})} props={props} sx={sx} />
}
