import React, { ReactElement } from 'react'

import Section from '@components/core/container/Section'
import Headline from '@components/core/text/Headline'
import { Box, Breakpoint, Theme } from '@mui/material'

import Button from '@components/core/input/Button'

export type StageProps = {
  variant?: 'home'
  headline?: string
  subline?: string
  background: MCDC.Props.IGlobalImage | MCDC.Props.IGlobalVideo
  linkTo?: MCDC.Props.IButtonTo
  maxWidth?: Breakpoint
  sx?: MCDC.Props.IDefault['sx']
}

export default function Stage({
  headline,
  subline,
  background,
  linkTo,
  maxWidth,
  sx,
}: StageProps): ReactElement {
  const image =
    (background as MCDC.Props.IGlobalVideo)?.image ||
    (background as MCDC.Props.IGlobalImage)
  const theme = image?.theme
  const withVideo = !!(background as MCDC.Props.IGlobalVideo)?.playlist
  return (
    <Section
      theme={theme}
      background={background}
      backgroundLoading="eager"
      backgroundPosition={withVideo ? 'top center' : undefined}
      maxWidth={maxWidth}
      paddingSize={!withVideo ? 'large' : 'small'}
      sx={[
        sx,
        {
          height: withVideo ? 'calc(var(--vh, 1vh) * 100 - 64px)' : undefined,
          maxHeight: withVideo ? '1100px' : undefined,
        },
      ]}
    >
      {(headline || linkTo) && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-end',
            height: '100%',
            position: 'relative',
          }}
        >
          {headline && (
            <Headline
              variant="h1"
              sx={{
                textAlign: 'center',
              }}
              isHtml
            >
              {headline}
            </Headline>
          )}
          {subline && (
            <Headline
              variant={'h5'}
              sx={{
                mt: 2,
                textAlign: 'center',
              }}
              isHtml
            >
              {subline}
            </Headline>
          )}
          {linkTo && (
            <Button
              to={linkTo.url}
              isExternal={linkTo.isExternal}
              isDisabled={linkTo.isDisabled}
              isLoading={linkTo.isLoading}
              onClick={linkTo.onClick}
              color="primary"
              sx={(theme: Theme) => ({
                mt: 8,
                visibility: linkTo.isDisabled ? 'hidden' : undefined,
                [theme.breakpoints.up('md')]: {
                  minWidth: '300px',
                  alignSelf: 'flex-start',
                },
              })}
            >
              {linkTo.label}
            </Button>
          )}
        </Box>
      )}
    </Section>
  )
}
