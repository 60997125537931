import React, { ElementType, ReactElement } from 'react'
import { IconSize } from '@config/theme/definitions/fontSize'
import { IconSizeKeyType } from '@mui/material/styles/createTheme'
import SvgIcon from '@mui/material/SvgIcon'

import Add from '@static/images/icons/material/add.svg'
import AccountCircle from '@static/images/icons/material/account_circle.svg'
import AlarmOn from '@static/images/icons/material/alarm_on.svg'
import ArrowBackIosNew from '@static/images/icons/material/arrow_back_ios_new.svg'
import ArrowDropDown from '@static/images/icons/material/arrow_drop_down.svg'
import ArrowForwardIos from '@static/images/icons/material/arrow_forward_ios.svg'
import CalendarMonth from '@static/images/icons/material/calendar_month.svg'
import Check from '@static/images/icons/material/check.svg'
import ChevronLeft from '@static/images/icons/material/chevron_left.svg'
import ChevronRight from '@static/images/icons/material/chevron_right.svg'
import Close from '@static/images/icons/material/close.svg'
import ExpandLess from '@static/images/icons/material/expand_less.svg'
import ExpandMore from '@static/images/icons/material/expand_more.svg'
import Favorite from '@static/images/icons/material/favorite.svg'
import FilterAlt from '@static/images/icons/material/filter_alt.svg'
import FilterList from '@static/images/icons/material/filter_list.svg'
import Info from '@static/images/icons/material/info.svg'
import LooksOne from '@static/images/icons/material/looks_one.svg'
import Menu from '@static/images/icons/material/menu.svg'
import MyLocation from '@static/images/icons/material/my_location.svg'
import PinDrop from '@static/images/icons/material/pin_drop.svg'
import PlayCircle from '@static/images/icons/material/play_circle.svg'
import Search from '@static/images/icons/material/search.svg'
import Sort from '@static/images/icons/material/sort.svg'
import SwapVert from '@static/images/icons/material/swap_vert.svg'
import Backup from '@static/images/icons/material/backup.svg'
import Delete from '@static/images/icons/material/delete.svg'
import Error from '@static/images/icons/material/error.svg'
import CheckCircle from '@static/images/icons/material/check_circle.svg'
import LinkedIn from '@static/images/icons/linkedin.svg'

/**
Settings used https://fonts.google.com/icons
  .material-symbols-rounded {
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
  }
 */

const icons: { [key: string]: any } = {
  add: Add,
  account_circle: AccountCircle,
  alarm_on: AlarmOn,
  arrow_back_ios_new: ArrowBackIosNew,
  arrow_drop_down: ArrowDropDown,
  arrow_forward_ios: ArrowForwardIos,
  calendar_month: CalendarMonth,
  check: Check,
  checkCircle: CheckCircle,
  chevron_left: ChevronLeft,
  chevron_right: ChevronRight,
  close: Close,
  expand_less: ExpandLess,
  expand_more: ExpandMore,
  favorite: Favorite,
  filter_alt: FilterAlt,
  filter_list: FilterList,
  info: Info,
  looks_one: LooksOne,
  menu: Menu,
  my_location: MyLocation,
  pin_drop: PinDrop,
  play_circle: PlayCircle,
  search: Search,
  sort: Sort,
  swap_vert: SwapVert,
  backup: Backup,
  delete: Delete,
  error: Error,
  linkedin: LinkedIn,
}

export type IconProps = {
  variant?:
    | 'add'
    | 'account_circle'
    | 'alarm_on'
    | 'arrow_back_ios_new'
    | 'arrow_drop_down'
    | 'arrow_forward_ios'
    | 'calendar_month'
    | 'check'
    | 'checkCircle'
    | 'chevron_left'
    | 'chevron_right'
    | 'close'
    | 'expand_less'
    | 'expand_more'
    | 'favorite'
    | 'filter_alt'
    | 'filter_list'
    | 'info'
    | 'looks_one'
    | 'menu'
    | 'my_location'
    | 'pin_drop'
    | 'play_circle'
    | 'search'
    | 'sort'
    | 'swap_vert'
    | 'backup'
    | 'delete'
    | 'error'
    | 'linkedin'
  size?: IconSizeKeyType
  component?: ElementType<any>
  sx?: MCDC.Props.IDefault['sx']
  className?: MCDC.Props.IDefault['className']
}

export default function Icon({
  variant = 'search',
  size = 'medium',
  className,
  sx,
}: IconProps): ReactElement | null {
  const sizePX = IconSize.get(size)
  const Svg = icons[variant]

  if (!Svg) return null

  return (
    <SvgIcon
      className={className}
      component={Svg}
      sx={[
        {
          fontSize: sizePX,
        },
        sx,
      ]}
    />
  )
}
