import React, { ComponentType, ReactElement } from 'react'
import { Box } from '@mui/material'

import Youtube from '@static/images/icons/social-youtube.svg'
import XING from '@static/images/icons/social-xing.svg'
import Facebook from '@static/images/icons/social-facebook.svg'
import Instagram from '@static/images/icons/social-instagram.svg'
import Kununu from '@static/images/icons/social-kununu.svg'
import LinkedIn from '@static/images/icons/social-linkedin.svg'

const icons: { [key: string]: any } = {
  Youtube,
  XING,
  Facebook,
  Instagram,
  Kununu,
  LinkedIn,
}

export type SocailIconProps = {
  variant?:
    | 'Youtube'
    | 'XING'
    | 'Facebook'
    | 'Instagram'
    | 'Kununu'
    | 'LinkedIn'
  component?: ComponentType
  sx?: MCDC.Props.IDefault['sx']
}

/**
 * SocailIcon
 */
export default function SocailIcon({
  variant = 'Instagram',
  sx,
}: SocailIconProps): ReactElement | null {
  const Svg = icons[variant]

  if (!Svg) return null
  return <Box sx={sx} component={Svg} />
}
