import React, { ReactElement } from 'react'
import { Box, TextField } from '@mui/material'
import { DatePicker as MobileDatePicker } from '@mui/x-date-pickers/DatePicker'
import { Dayjs } from 'dayjs'

export type DatePickerProps = {
  id: string
  label?: string
  placeholder?: string
  defaultValue?: string
  isDisabled?: boolean
  isRequired?: boolean
  isError?: boolean
  onChange: (value: string | null) => void
  sx?: MCDC.Props.IDefault['sx']
}

export default function DatePicker({
  id,
  label,
  placeholder,
  defaultValue,
  isRequired,
  isError,
  isDisabled,
  onChange,
  sx,
}: DatePickerProps): ReactElement {
  const [value, setValue] = React.useState(defaultValue || null)
  return (
    <Box sx={sx}>
      <MobileDatePicker
        label={label}
        value={value}
        disabled={isDisabled}
        disableFuture
        closeOnSelect
        toolbarTitle=""
        showToolbar={false}
        views={['year', 'month', 'day']}
        openTo="day"
        DialogProps={{
          sx: {
            '.MuiDialogActions-root': {
              display: 'none',
            },
          },
        }}
        onChange={(dayjs: Dayjs | null) => {
          const value = dayjs?.format('YYYY-MM-DD') || null
          setValue(value)
          if (onChange) onChange(value)
        }}
        InputProps={{
          id,
          name: id,
          placeholder,
          error: isError,
        }}
        renderInput={(params) => <TextField {...params} variant="filled" />}
      />
    </Box>
  )
}
