import React, { ReactElement } from 'react'

import { Box, Drawer } from '@mui/material'

import NavigationItem from '../NavigationItem'
import Button from '@components/core/input/Button'

export type NavigationDrawerProps = {
  items: MCDC.Props.ILinkTo[]
  metaItems: MCDC.Props.ILinkTo[]
  activeIndex: number
  onClose: () => void
  isOpen: boolean
  sx?: MCDC.Props.IDefault['sx']
}

export default function NavigationDrawer({
  items,
  metaItems,
  activeIndex,
  onClose,
  isOpen,
  sx = {},
}: NavigationDrawerProps): ReactElement {
  return (
    <Drawer
      elevation={0}
      anchor="right"
      variant="temporary"
      open={isOpen}
      onClose={onClose}
      transitionDuration={500}
      PaperProps={{
        sx: [
          {
            width: '100vw',
            display: 'flex',
            height: 'calc(100% - 64px)',
          },
          sx,
        ],
      }}
      sx={sx}
    >
      <Box
        component="ul"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          p: 0,
          '> * + *': { mt: 6 },
          my: 0,
        }}
      >
        {items.map((entry, index) => (
          <NavigationItem
            to={entry.url}
            isExternal={entry.isExternal}
            count={entry.count}
            onClick={onClose}
            isSelected={activeIndex === index}
            key={index}
          >
            {entry.label}
          </NavigationItem>
        ))}
        {metaItems.map((entry, index) => (
          <Button
            to={entry.url}
            variant="expanded"
            color="primary"
            sx={{ mt: 6 }}
            onClick={onClose}
            key={index}
          >
            {entry.label}
          </Button>
        ))}
      </Box>
    </Drawer>
  )
}
