import { Components, Theme } from '@mui/material'

export const TypographyOverrides = (
  theme: Theme
): Components['MuiTypography'] => ({
  defaultProps: {},
  styleOverrides: {
    root: {
      wordBreak: 'break-word',
      'ul&': {
        listStyle: 'none',
        padding: 0,
        li: {
          background: `url("data:image/svg+xml,%3Csvg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.1923 0.89103C14.736 0.90555 14.3027 1.11374 13.9842 1.47134C11.2036 4.51845 8.66538 7.50665 5.99523 10.4883L4.4258 9.11226L2.85637 7.73625C2.49235 7.41771 2.02719 7.27051 1.56392 7.32679C1.10042 7.38308 0.676538 7.63818 0.385259 8.03646C0.0939637 8.43452 -0.0408565 8.94296 0.0108208 9.44979C0.0622983 9.95641 0.295782 10.4202 0.659793 10.7385L2.85615 12.6598L5.05252 14.5812C5.39141 14.8763 5.81795 15.0244 6.25016 14.9967C6.68261 14.969 7.09032 14.7675 7.3953 14.4311C10.5552 10.9683 13.3861 7.5672 16.4736 4.18356C16.8117 3.82079 17.0017 3.32378 17 2.80575C16.9986 2.28773 16.8057 1.79203 16.4654 1.43176C16.1249 1.07123 15.6659 0.876064 15.1923 0.89103Z' fill='%23FFBC0D'/%3E%3C/svg%3E%0A") no-repeat left top`,
          backgroundPosition: '0px 5px',
          paddingLeft: '24px',
          '& + li': {
            marginTop: '16px',
          },
        },
      },
      'ol&': {
        paddingLeft: '1.5em',
        li: {
          // paddingLeft: '24px',
          '& + li': {
            marginTop: '16px',
          },
        },
      },
      '> p:not([class])': {
        marginTop: 0,
        marginBottom: 0,
      },
    },
  },
})
