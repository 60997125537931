import { Components as MuiComponents, Theme } from '@mui/material'
import { ButtonOverrides } from './components/Button'
import { ChipOverrides } from './components/Chip'
import { CssBaselineOverrides } from './components/CssBaseline'
import { SvgIconOverrides } from './components/SvgIcon'
import { LinkOverrides } from './components/Link'
import { ContainerOverrides } from './components/Container'
import { MenuItemOverrides, MenuOverrides } from './components/Menu'
import {
  AccordionDetailsOverrides,
  AccordionOverrides,
  AccordionSummaryOverrides,
} from './components/Accordion'
import {
  MuiFormControlOverrides,
  MuiInputBaseOverrides,
  MuiFilledInputOverrides,
  MuiOutlinedInputOverrides,
  MuiFormHelperTextOverrides,
  MuiInputLabelOverrides,
  MuiRadioOverrides,
  MuiCheckboxOverrides,
} from './components/Formfields'
import { CardOverrides } from './components/Card'
import { PaperOverrides } from './components/Paper'
import { TypographyOverrides } from './components/Typography'
import { AppBarOverrides } from './components/AppBar'
import { BreadcrumbsOverrides } from './components/Breadcrumbs'
import {
  PaginationItemOverrides,
  PaginationOverrides,
} from './components/Pagination'
import { AutocompleteOverrides } from './components/Autocomplete'
import { ListItemOverrides, ListOverrides } from './components/List'
import { SnackbarOverrides } from './components/Snackbar'
import { AlertOverrides } from './components/Alert'
import { LinearProgressOverrides } from './components/ProgressBar'

export const ComponentOverrides = (theme: Theme): MuiComponents => ({
  MuiCssBaseline: CssBaselineOverrides(theme),
  MuiSvgIcon: SvgIconOverrides(theme),
  MuiButton: ButtonOverrides(theme),
  MuiChip: ChipOverrides(theme),
  MuiAppBar: AppBarOverrides(theme),
  MuiAutocomplete: AutocompleteOverrides(theme),
  MuiAccordion: AccordionOverrides(theme),
  MuiAccordionSummary: AccordionSummaryOverrides(theme),
  MuiAccordionDetails: AccordionDetailsOverrides(theme),
  MuiFormControl: MuiFormControlOverrides(theme),
  MuiInputBase: MuiInputBaseOverrides(theme),
  MuiFilledInput: MuiFilledInputOverrides(theme),
  MuiOutlinedInput: MuiOutlinedInputOverrides(theme),
  MuiRadio: MuiRadioOverrides(theme),
  MuiCheckbox: MuiCheckboxOverrides(theme),
  MuiFormHelperText: MuiFormHelperTextOverrides(theme),
  MuiInputLabel: MuiInputLabelOverrides(theme),
  MuiLink: LinkOverrides(theme),
  MuiContainer: ContainerOverrides(theme),
  MuiMenu: MenuOverrides(theme),
  MuiMenuItem: MenuItemOverrides(theme),
  MuiList: ListOverrides(theme),
  MuiListItem: ListItemOverrides(theme),
  MuiCard: CardOverrides(theme),
  MuiPaper: PaperOverrides(theme),
  MuiTypography: TypographyOverrides(theme),
  MuiBreadcrumbs: BreadcrumbsOverrides(theme),
  MuiPagination: PaginationOverrides(theme),
  MuiPaginationItem: PaginationItemOverrides(theme),
  MuiSnackbar: SnackbarOverrides(theme),
  MuiAlert: AlertOverrides(theme),
  MuiLinearProgress: LinearProgressOverrides(theme),
})
