import React from 'react'
import {
  Box,
  ListItem,
  ListItemButton,
  ListItemText,
  Theme,
} from '@mui/material'

export type FormStepperItemProps = {
  label: string
  isSelected: boolean
  isDisabled: boolean
  onClick: () => void
  sx?: MCDC.Props.IDefault['sx']
}

export function FormStepperItem({
  label,
  isSelected,
  isDisabled,
  onClick,
  sx,
}: FormStepperItemProps) {
  return (
    <ListItem sx={[{ p: 0 }, sx]}>
      <ListItemButton
        onClick={onClick}
        disabled={isDisabled}
        sx={(theme: Theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          [theme.breakpoints.down('md')]: {
            px: 2,
          },
        })}
      >
        <Box
          sx={{
            width: '13px',
            height: '13px',
            backgroundColor: isSelected ? 'primary.main' : 'grey.500',
            borderRadius: '100%',
            transition: 'background-color 500ms ease, color 500ms ease;',
          }}
        />
        <ListItemText
          sx={(theme: Theme) => ({
            fontSize: '12px',
            fontWeight: isSelected ? 700 : undefined,
            [theme.breakpoints.up('md')]: {
              fontSize: '14px',
            },
          })}
          disableTypography
        >
          <span dangerouslySetInnerHTML={{ __html: label }} />
        </ListItemText>
      </ListItemButton>
    </ListItem>
  )
}
