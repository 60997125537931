import React, { ReactElement, SyntheticEvent } from 'react'
import { RadioGroup as MuiRadioGroup, FormLabel, Box } from '@mui/material'
import Radio from '../../fields/Radio'
import Copy from '@components/core/text/Copy'

export type RadioGroupProps = MCDC.Props.IDefault & {
  id?: string
  name: string
  label: string
  info?: string
  copy?: string
  value?: any
  options: { label: string; value: any }[]
  isError: boolean
  onChange: (e: SyntheticEvent<Element, Event>, value: any) => void
}

export default function RadioGroup({
  id,
  name,
  label,
  info,
  copy,
  value,
  onChange,
  options,
  isError,
  sx,
}: RadioGroupProps): ReactElement {
  return (
    <Box id={id} sx={sx}>
      {label && (
        <FormLabel id={`${name}-label`}>
          <Copy>
            <strong>{label}</strong>
            {info}
          </Copy>
        </FormLabel>
      )}
      {copy && <Copy>{copy}</Copy>}
      <MuiRadioGroup
        name={name}
        aria-labelledby={`${name}-label`}
        value={value}
        onChange={(ev, value) => {
          const option = options.find((entry) => String(entry.value) === value)
          onChange(ev, option?.value !== undefined ? option?.value : undefined)
        }}
        sx={{ mt: 3 }}
      >
        {options.map((entry, index) => (
          <Radio
            {...entry}
            name={`${name}-${index}`}
            key={index}
            isError={isError}
          />
        ))}
      </MuiRadioGroup>
    </Box>
  )
}
