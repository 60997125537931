import React, { ReactElement } from 'react'
import { Box, IconButton, LinearProgress } from '@mui/material'

import Copy from '@components/core/text/Copy'
import Icon from '@components/core/media/Icon'
import { formatBytes } from '@utils/functional'

export type FileUploaderItemProps = MCDC.Props.IDefault & {
  name: string
  size: number
  progress: number
  message?: string
  isError?: boolean
  onRemove: () => void
}

export default function FileUploaderItem({
  name,
  size = 0,
  progress = 0,
  message,
  isError,
  onRemove,
  sx,
}: FileUploaderItemProps): ReactElement {
  const isLoaded = progress === 100
  const color = isError ? 'error' : isLoaded ? 'success' : undefined
  return (
    <Box
      sx={[
        {
          position: 'relative',
          backgroundColor: 'common.white',
          borderRadius: '8px',
          p: 3.5,
        },
        sx,
      ]}
    >
      <Copy sx={{ lineHeight: 1 }}>{name}</Copy>
      <LinearProgress
        variant="determinate"
        value={progress}
        color={color}
        sx={{ mt: 4 }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Copy variant="small" sx={{ color: 'grey.400', mt: 2 }}>
          {formatBytes(size)}
        </Copy>
        {!isLoaded && !isError && (
          <Copy variant="small" sx={{ mt: 2, color: 'grey.400' }}>
            {progress} %
          </Copy>
        )}
        {(isLoaded || isError) && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              color: color ? `${color}.main` : undefined,
            }}
          >
            <Icon variant={isError ? 'error' : 'checkCircle'} sx={{ mt: 1 }} />
            <Copy variant="small" sx={{ mt: 2, ml: 1 }}>
              {message}
            </Copy>
          </Box>
        )}
      </Box>

      <IconButton
        color="inherit"
        sx={{ position: 'absolute', top: 0, right: 0 }}
        onClick={() => onRemove()}
      >
        <Icon variant="close" />
      </IconButton>
    </Box>
  )
}
