import { isBrowser } from '@utils/functional'

export const openConsentPreferencesDialog = (): void => {
  if (!isBrowser) {
    return
  }

  if (typeof window.OneTrust !== 'undefined') {
    window.OneTrust.ToggleInfoDisplay()
  }
}
