/* eslint-disable @typescript-eslint/no-unused-vars */
import { HistoryLocation } from '@gatsbyjs/reach-router'

import React, { ReactNode, ReactElement, useState, useEffect } from 'react'

export interface IGlobalStateContext {
  setLocation: (value: HistoryLocation) => void
  setActiveDrawer: (onClose?: () => void) => void
  activeDrawer?: { onClose: () => void }
  location?: HistoryLocation
}

export const GlobalStateContext = React.createContext<IGlobalStateContext>({
  setActiveDrawer: () => console.log(''),
  setLocation: (value: HistoryLocation) => console.log('setLocation', value),
})

export type GlobalStateProviderProps = {
  children: ReactNode
  location?: HistoryLocation
}

export default function GlobalStateProvider({
  children,
  location: pageLocation,
}: GlobalStateProviderProps): ReactElement {
  const [location, setCurrentLocation] = useState<HistoryLocation | undefined>(
    pageLocation
  )
  const [activeDrawer, setActiiveDrawerState] = useState<
    { onClose: () => void } | undefined
  >()

  function setLocation(value: HistoryLocation) {
    setCurrentLocation(value)
  }

  function setActiveDrawer(onClose?: () => void) {
    setActiiveDrawerState(onClose ? { onClose } : undefined)
  }

  return (
    <GlobalStateContext.Provider
      value={{
        location,
        activeDrawer,
        setLocation,
        setActiveDrawer,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  )
}
