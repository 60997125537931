import React, { ReactElement } from 'react'
import Section from '@components/core/container/Section'
import { Box, Grid, Theme } from '@mui/material'

import FormField, { FieldTextProps } from '../FormField'
import useForms from '@components/site/hooks/useForms'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from '@components/core/input/Button'
import { navigate } from 'gatsby'
import Headline from '@components/core/text/Headline'

export type ContactFormProps = {
  headline: string
  options: MCDC.Props.IOption[]
  linkTo?: MCDC.Props.ILinkTo
  sx: MCDC.Props.IDefault['sx']
}

export default function ContactForm({
  headline,
  options,
  linkTo,
  sx,
}: ContactFormProps): ReactElement {
  const intl = useIntl()
  const { submitContact, fields } = useForms()

  return (
    <Section
      theme="medium"
      maxWidth="sm"
      paddingTop={false}
      paddingSize="small"
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sx={{ mb: 4 }}>
          <Headline variant="h4">{headline}</Headline>
        </Grid>
        <Grid item xs={12} sx={{ mb: 4 }}>
          <FormField
            id="subject"
            valueKey="subject"
            type="select"
            isRequired
            props={{
              placeholder: intl.messages['label.chooseTopic'] as string,
              options: options,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            id="firstname"
            valueKey="firstname"
            type="text"
            isRequired
            props={
              {
                label: intl.messages['label.firstname'],
              } as FieldTextProps
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            id="surname"
            valueKey="surname"
            type="text"
            isRequired
            props={
              {
                label: intl.messages['label.surname'],
              } as FieldTextProps
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            id="email"
            valueKey="email"
            type="text"
            isRequired
            props={
              {
                label: intl.messages['label.email'],
              } as FieldTextProps
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            id="phone"
            valueKey="phone"
            type="text"
            props={
              {
                label: intl.messages['label.phone'],
              } as FieldTextProps
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            id="message"
            valueKey="message"
            type="text"
            isRequired
            props={
              {
                multiline: true,
                rows: 5,
                placeholder: intl.messages['label.yourMessage'],
              } as FieldTextProps
            }
          />
        </Grid>
        {!!fields['phone']?.value && (
          <Grid item xs={12}>
            <FormField
              id="consentPhone"
              valueKey="has_consent_phone"
              type="checkbox"
              props={
                {
                  label: intl.messages['label.consentPhone'],
                } as FieldTextProps
              }
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormField
            id="freindlyCapture"
            valueKey="frc-captcha-solution"
            type="capture"
            isRequired
            props={{}}
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 16 }}>
        <Button
          color="primary"
          onClick={async () => {
            const isSuccess = await submitContact((values) => values)
            if (isSuccess) {
              navigate(linkTo?.url || '/')
            }
          }}
          sx={(theme: Theme) => ({
            [theme.breakpoints.down('md')]: {
              width: '100%',
            },
          })}
        >
          <FormattedMessage id="label.send" />
        </Button>
      </Box>
    </Section>
  )
}
