import React, { ReactElement } from 'react'

import { ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import theme from '@config/theme'
import MetaTags from '@components/site/container/MetaTags'
import LightboxProvider from '@components/site/providers/LightboxProvider'
import GlobalStateProvider from '@components/site/providers/GlobalStateProvider'

import { HistoryLocation } from '@gatsbyjs/reach-router'

import StaticContentfulProvider from './StaticContentfulProvider'

import useContentfulGlobalFaq from '../hooks/contentful/useContentfulGlobalFaq'
import useContentfulFormSteps from '../hooks/contentful/useContentfulFormSteps'
import FormsProvider from './FormsProvider'

type ProvidersProps = {
  children: MCDC.Props.IDefault['children']
  pageContext?: MCDC.Contentful.IPageContext
  location?: HistoryLocation
}

export default function Providers({
  children,
  pageContext,
  location,
}: ProvidersProps): ReactElement {
  const globalFaqs = useContentfulGlobalFaq()
  const { formStepPages, formSuccessPage } = useContentfulFormSteps()

  return (
    <ThemeProvider theme={theme}>
      {pageContext?.page?.metaTags && (
        <MetaTags
          locale={pageContext.locale}
          meta={pageContext.page.metaTags}
        />
      )}
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={pageContext?.locale}
      >
        <CssBaseline />
        <StaticContentfulProvider
          globalFaqs={globalFaqs}
          formStepPages={formStepPages}
        >
          <GlobalStateProvider location={location}>
            <LightboxProvider>
              <FormsProvider
                formStepPages={formStepPages}
                formSuccessPage={formSuccessPage}
                location={location}
              >
                {children}
              </FormsProvider>
            </LightboxProvider>
          </GlobalStateProvider>
        </StaticContentfulProvider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}
