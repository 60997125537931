export const applicationFormConfig: MCDC.Forms.FormConfig = {
  steps: [
    {
      key: 'general_information',
      templatePath: './index.tsx',
    },
    {
      key: 'skills',
      templatePath: './index.tsx',
    },
    {
      key: 'conditions',
      templatePath: './index.tsx',
    },
  ],
}

export const aplicationFormFixtures: MCDC.API.SubmitValues = {
  general_information: {
    firstname: 'Lorem',
    surname: 'Ipsum',
    street: 'Lorem Ipsum',
    street_number: '1234',
    zip: '12345',
    city: 'Lorem',
    email: 'lorem@ipsum.com',
    phone: '05555555',
    date_of_birth: '2023-02-01',
    back_channels: ['McDonald’s Webseite'],
    has_consent_phone: true,
  },
  skills: {
    fulltime: false,
    is_applicable_nationwide: false,
    explain_applicable_nationwide: ['aa', 'aaa'],
    has_experience_with_mcd_system: true,
    explain_experience_with_mcd_system: 'aa',
    is_working_for_mcd: true,
    explain_working_for_mcd: 'aa',
    has_health_impairments: false,
    has_german_residence_permit: true,
    has_german_skills: true,
    is_assessment_centre_ready: true,
    has_criminal_records: false,
    has_experience_with_franchise: true,
    explain_experience_with_franchise: 'aaa',
    has_staff_responsibility: true,
    important_skills: 'aa',
  },
  conditions: {
    has_equity: true,
    equity: 'aa',
    has_support_obligations: true,
    references: 'aa',
  },
  documents: [],
  'frc-captcha-solution': undefined as any,
}
