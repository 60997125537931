import React, { ReactElement } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import Headline from '@components/core/text/Headline'
import { Grid } from '@mui/material'
import Section from '@components/core/container/Section'
import InfoCard from '@components/core/card/InfoCard'
import Button from '@components/core/input/Button'

import { cardTransition, StyledMotionDiv } from '@utils/motion'
import { AnimatePresence } from 'framer-motion'
import Copy from '@components/core/text/Copy'

export type InfosProps = {
  headline: string
  copy?: MCDC.Props.RichText
  items: MCDC.Props.IGlobalInfo[]
  linkTo?: MCDC.Props.ILinkTo
  sx?: MCDC.Props.IDefault['sx']
}

export default function Infos({
  headline,
  copy,
  linkTo,
  items = [],
  sx,
}: InfosProps): ReactElement | null {
  const intl = useIntl()

  function renderInfoCard(props: MCDC.Props.IGlobalInfo, index: number) {
    return (
      <Grid item xs={12} key={index} sx={{ overflow: 'hidden' }}>
        <StyledMotionDiv {...cardTransition}>
          {
            <InfoCard
              headline={props.title}
              copy={props.description}
              image={props.image}
              linkTo={
                props.linkTo
                  ? {
                      ...props.linkTo,
                      label: intl.messages['label.discoverMore'] as string,
                    }
                  : undefined
              }
            />
          }
        </StyledMotionDiv>
      </Grid>
    )
  }

  return (
    <Section theme="primary" sx={[sx]} paddingSize="default" maxWidth="sm">
      <Headline sx={{ mb: 8, textAlign: 'center' }} component="h2" variant="h3">
        {headline}
      </Headline>
      {copy && (
        <Copy sx={{ mb: 7 }} component="div">
          {copy}
        </Copy>
      )}
      <AnimatePresence exitBeforeEnter>
        <Grid container spacing={6}>
          {items.map(renderInfoCard)}
        </Grid>
      </AnimatePresence>
      {linkTo && (
        <Button color="secondary" sx={{ mt: 8 }} to={linkTo.url}>
          <FormattedMessage id={'label.discoverMore'} />
        </Button>
      )}
    </Section>
  )
}
