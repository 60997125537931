import { Components, Theme } from '@mui/material'

import FontSize from '../../definitions/fontSize'

export const ChipOverrides = (theme: Theme): Components['MuiChip'] => ({
  styleOverrides: {
    root: {
      '&.MuiChip-large': {
        height: '40px',
        fontSize: FontSize.get('base'),
        padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
        borderRadius: '20px',
      },
      '&.Mui-bold': {
        fontWeight: 700,
      },
    },
    deleteIcon: {
      color: 'currentcolor',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginLeft: '4px',
      marginRight: 0,
    },

    deletable: {},
    sizeSmall: {
      height: '20px',
      fontSize: FontSize.get('small'),
      padding: `0 ${theme.spacing(2)}`,
    },
    sizeMedium: {
      height: '24px',
      fontSize: FontSize.get('small'),
      padding: `0 ${theme.spacing(2)}`,
    },
    label: {
      padding: 0,
    },
    labelSmall: {
      marginTop: '-1px',
    },
    filled: {
      '&.MuiChip-colorPrimary': {
        backgroundColor: theme.palette.primary.main,
      },
      '&.MuiChip-colorDefault': {
        backgroundColor: theme.palette.text.primary,
        color: theme.palette.common.white,
      },
      '&.MuiChip-colorSecondary': {
        backgroundColor: theme.palette.common.white,
      },
    },
    outlined: {
      backgroundColor: 'tranparent',
      '&.MuiChip-colorPrimary': {
        border: `2px solid ${theme.palette.common.white}`,
        color: theme.palette.common.white,
      },
      '&.MuiChip-colorDefault': {
        border: `2px solid ${theme.palette.text.primary}`,
        color: theme.palette.text.primary,
      },
      '&.MuiChip-colorSecondary': {
        border: `2px solid ${theme.palette.common.white}`,
        color: theme.palette.common.white,
      },
      '&.Mui-selected': {
        '&.MuiChip-colorPrimary': {
          backgroundColor: theme.palette.primary.main,
        },
        '&.MuiChip-colorDefault': {
          backgroundColor: theme.palette.text.primary,
          color: theme.palette.common.white,
        },
        '&.MuiChip-colorSecondary': {
          backgroundColor: theme.palette.common.white,
        },
      },
    },
  },
})
