import React, { ReactElement } from 'react'
import Box from '@mui/material/Box'
import { SelectChangeEvent, Theme } from '@mui/material'

import Section from '@components/core/container/Section'
import SelectInput from '@components/core/form/fields/SelectInput'
import Headline from '@components/core/text/Headline'
import Image from '@components/core/media/Image'
import Copy from '@components/core/text/Copy'
import { useIntl } from 'react-intl'

export type HeroDropDownProps = {
  headline: string
  copy: MCDC.Props.RichText
  options: MCDC.Props.IOption[]
  value?: MCDC.Props.IOption | null
  image: MCDC.Props.IAssetImage
  sx?: MCDC.Props.IDefault['sx']
  onChange: (e: SelectChangeEvent<string>, child: React.ReactNode) => void
}

export default function HeroDropDown({
  headline,
  copy,
  image,
  options,
  value,
  onChange,
  sx,
}: HeroDropDownProps): ReactElement {
  const intl = useIntl()
  return (
    <Section maxWidth="sm" sx={sx}>
      <Box
        sx={(theme: Theme) => ({
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '500px',
          backgroundColor: 'common.white',
          background:
            'linear-gradient(0deg, rgba(247,247,247,1) 0%, rgba(255,255,255,1) 100%)',
          px: theme.gutter('sm'),
          py: theme.padding('small', 'sm'),
          borderRadius: '8px',
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            minHeight: '351px',
            overflow: 'hidden',
            px: theme.gutter('md'),
          },
          [theme.breakpoints.up('lg')]: {
            mx: theme.spacing(-theme.gutter('lg')),
            px: theme.gutter('lg'),
          },
        })}
      >
        <Box
          sx={(theme: Theme) => ({
            display: 'flex',
            flexDirection: 'column',
            zIndex: 1,
            [theme.breakpoints.up('md')]: {
              width: '50%',
            },
          })}
        >
          <Headline variant="h3" component="div" sx={{ mb: 4 }}>
            {headline}
          </Headline>
          <Copy component="div">{copy}</Copy>
          <Box
            sx={(theme: Theme) => ({
              position: 'relative',
              height: '100%',
              [theme.breakpoints.up('md')]: {
                display: 'none',
              },
            })}
          >
            <Image
              {...image}
              objectFit="contain"
              objectPosition="center"
              scaleToFit
              sx={{
                width: '100%',
                height: '100%',
              }}
            />
          </Box>
          {options?.length > 1 && (
            <Box
              sx={{
                mt: 'auto',
              }}
            >
              <SelectInput
                id="heroDropDown"
                onChange={onChange}
                options={options}
                value={value}
                placeholder={intl.messages['label.chooseTopic'] as string}
                withoutBorder
              />
            </Box>
          )}
        </Box>
        <Box
          sx={(theme: Theme) => ({
            position: 'relative',
            width: '45%',
            ml: 'auto',
            mt: -8,
            mr: -14,
            mb: -8,
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          })}
        >
          <Image
            {...image}
            objectFit="contain"
            sx={{
              width: 'auto',
              height: '100%',
            }}
          />
        </Box>
      </Box>
    </Section>
  )
}
