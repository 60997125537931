import axios, { AxiosError, AxiosResponse } from 'axios'

const API_URI = process.env.API_URI || '/api'
let uuid: string

export const api = {
  /**
   * api/Submit
   * @param value
   * @returns
   */
  submit: async (
    values: MCDC.API.SubmitValues
  ): Promise<
    | AxiosResponse<MCDC.API.ApplyResponseData>
    | AxiosResponse<MCDC.API.ApplyResponseErrorData>
  > => {
    let response:
      | AxiosResponse<MCDC.API.ApplyResponseData>
      | AxiosResponse<MCDC.API.ApplyResponseErrorData>
    try {
      response = await axios.post(
        `${API_URI}/franchise-application-request/apply/${uuid}/submit`,
        {
          identifier: {
            uuid,
          },
          ...values,
        }
      )
    } catch (e) {
      response = (e as AxiosError)
        .response as AxiosResponse<MCDC.API.ApplyResponseErrorData>
    }

    return response
  },
  submitContact: async (
    values: MCDC.API.SubmitContactValues
  ): Promise<
    | AxiosResponse<MCDC.API.ContactResponseData>
    | AxiosResponse<MCDC.API.ContactResponseErrorData>
  > => {
    let response:
      | AxiosResponse<MCDC.API.ContactResponseData>
      | AxiosResponse<MCDC.API.ContactResponseErrorData>
    try {
      response = await axios.post(
        `${API_URI}/contact-service/contact/submit`,
        values
      )
    } catch (e) {
      response = (e as AxiosError)
        .response as AxiosResponse<MCDC.API.ContactResponseErrorData>
    }

    return response
  },
  initiate: async (): Promise<
    | AxiosResponse<MCDC.API.InitiateResponseData>
    | AxiosResponse<MCDC.API.InitiateResponseErrorData>
  > => {
    let response:
      | AxiosResponse<MCDC.API.InitiateResponseData>
      | AxiosResponse<MCDC.API.InitiateResponseErrorData>
    try {
      response = await axios.post(
        `${API_URI}/franchise-application-request/apply/initiate`
      )
    } catch (e) {
      response = (e as AxiosError)
        .response as AxiosResponse<MCDC.API.InitiateResponseErrorData>
    }
    uuid = (response.data as MCDC.API.InitiateResponseData).uuid
    return response
  },
  upload: async (
    file: File,
    // eslint-disable-next-line @typescript-eslint/ban-types
    onProgress: (ev: ProgressEvent) => void
  ): Promise<
    | AxiosResponse<MCDC.API.UploadResponseData>
    | AxiosResponse<MCDC.API.UploadResponseErrorData>
  > => {
    let response:
      | AxiosResponse<MCDC.API.UploadResponseData>
      | AxiosResponse<MCDC.API.UploadResponseErrorData>
    try {
      const formData = new FormData()
      formData.append('document', file)

      response = await axios.post(
        `${API_URI}/franchise-application-request/apply/${uuid}/documents`,
        formData,
        { onUploadProgress: onProgress }
      )
    } catch (e) {
      response = (e as AxiosError)
        .response as AxiosResponse<MCDC.API.UploadResponseErrorData>
    }

    return response
  },
  delete: async (
    token: string
  ): Promise<
    | AxiosResponse<MCDC.API.DeleteUploadResponseData>
    | AxiosResponse<MCDC.API.DeleteUploadResponseErrorData>
  > => {
    let response:
      | AxiosResponse<MCDC.API.DeleteUploadResponseData>
      | AxiosResponse<MCDC.API.DeleteUploadResponseErrorData>
    try {
      response = await axios.delete(
        `${API_URI}/franchise-application-request/apply/${uuid}/documents/${token}`
      )
    } catch (e) {
      response = (e as AxiosError)
        .response as AxiosResponse<MCDC.API.DeleteUploadResponseErrorData>
    }

    return response
  },
}
