import React, { ReactElement } from 'react'
import { Box, Button as MuiButton, CircularProgress } from '@mui/material'
import { Link as GatsbyLink } from 'gatsby'
import clsx from 'clsx'

import TextButtonIcon from '@static/images/icons/textbutton-arrow.svg'
import Icon, { IconProps } from '@components/core/media/Icon'

export type ButtonProps = {
  id?: string
  to?: string
  onClick?: (event: React.SyntheticEvent) => void
  icon?: IconProps['variant']
  variant?: 'rounded' | 'expanded' | 'text'
  color?: 'default' | 'primary' | 'secondary'
  isActive?: boolean
  isDisabled?: boolean
  isDownload?: boolean
  isExternal?: boolean
  isLoading?: boolean
  children?: MCDC.Props.IDefault['children']
  sx?: MCDC.Props.IDefault['sx']
}

/**
 * MUI Button Wrapper
 */
export default React.forwardRef(function Button(
  {
    id,
    to,
    onClick,
    variant = 'rounded',
    color = 'default',
    icon,
    children,
    isExternal,
    isActive,
    isDisabled,
    isDownload,
    isLoading,
    sx = {},
  }: ButtonProps,
  ref: any
): ReactElement {
  const className = clsx({
    ['Mui-active']: isActive,
    ['Mui-loading']: isLoading,
    ['MuiButton-rounded']: variant === 'rounded',
  })

  const isInternal = to && !isExternal
  const LinkComponent = isInternal
    ? GatsbyLink
    : !to && onClick
    ? 'button'
    : 'a'
  const additionalProps = isExternal
    ? { href: to, target: '_blank', rel: 'noreferrer', download: isDownload }
    : { to }

  return (
    <MuiButton
      ref={ref}
      id={id}
      className={className}
      {...additionalProps}
      component={LinkComponent}
      variant={variant === 'text' ? 'text' : 'contained'}
      color={color === 'default' ? 'inherit' : color}
      onClick={onClick}
      disabled={isDisabled}
      startIcon={icon ? <Icon variant={icon} /> : undefined}
      endIcon={variant === 'text' ? <TextButtonIcon /> : undefined}
      disableFocusRipple={variant === 'text'}
      disableTouchRipple={variant === 'text'}
      sx={sx}
    >
      {isLoading && (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            color: !color ? 'common.white' : 'text.primary',
          }}
        />
      )}
      {children && (
        <Box
          className="MuiButton-label"
          component="span"
          sx={{ opacity: isLoading ? 0 : 1 }}
        >
          {children}
        </Box>
      )}
    </MuiButton>
  )
})
