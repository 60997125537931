import React, { ReactElement, useState } from 'react'
import { FormLabel, Box } from '@mui/material'

import Checkbox from '../../fields/Checkbox'
import Copy from '@components/core/text/Copy'

export type CheckboxGroupProps = MCDC.Props.IDefault & {
  id?: string
  name: string
  label: string
  info?: string
  copy?: string
  defaultValue?: string[]
  options: { label: string; value: string }[]
  isError: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>, value: string[]) => void
}

export default function CheckboxGroup({
  id,
  name,
  label,
  info,
  copy,
  defaultValue = [],
  isError,
  onChange,
  options,
  sx,
}: CheckboxGroupProps): ReactElement {
  const [selectedOptions, setSelectedOptions] = useState<string[]>(defaultValue)

  function changeHandler(
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    const value = e.target.value
    const options = [...selectedOptions]
    if (checked && !selectedOptions.includes(value)) {
      options.push(value)
    } else if (!checked && selectedOptions.includes(value)) {
      options.splice(options.indexOf(value), 1)
    }

    if (onChange) {
      onChange(e, options)
    }

    setSelectedOptions(options)
  }

  return (
    <Box id={id} sx={sx}>
      {label && (
        <FormLabel id={`${name}-label`} sx={{ display: 'block', mb: 4 }}>
          <Copy>
            <strong>{label}</strong>
            {info}
          </Copy>
        </FormLabel>
      )}
      {copy && <Copy>{copy}</Copy>}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          mt: 3,
        }}
      >
        {options.map((entry, index) => (
          <Checkbox
            {...entry}
            name={`${name}-${index}`}
            key={index}
            isChecked={selectedOptions.includes(entry.value) || false}
            isError={isError}
            onChange={changeHandler}
          />
        ))}
      </Box>
    </Box>
  )
}
