import React, { ReactElement } from 'react'
import { Box, Container, Theme } from '@mui/material'

import Logo from '@components/core/media/Logo'
import NavigationItem from './components/NavigationItem'
import MenuToggle from '@components/core/input/MenuToggle'
import { Link } from '@gatsbyjs/reach-router'
import { navigate } from 'gatsby'
import Button from '@components/core/input/Button'

export type NavigationProps = {
  itemHome: MCDC.Props.ILinkTo
  itemForm: MCDC.Props.ILinkTo
  items: MCDC.Props.ILinkTo[]
  activeIndex: number
  isOpen: boolean
  isDisabled: boolean
  onToggle: () => void
  sx?: MCDC.Props.IDefault['sx']
}

export default function Navigation({
  items,
  itemHome,
  itemForm,
  activeIndex,
  isOpen,
  isDisabled,
  onToggle,
  sx,
}: NavigationProps): ReactElement {
  return (
    <Container
      maxWidth="xl"
      sx={[
        (theme: Theme) => ({
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          height: '64px',
          zIndex: theme.zIndex.drawer + 1,
          px: `${theme.spacing(theme.gutter('sm'))} !important`,
        }),
        sx,
      ]}
    >
      <Link
        to={itemHome.url}
        onClick={() => {
          if (isOpen) {
            onToggle()
          }
        }}
      >
        <Logo sx={{ mr: 4 }} />
      </Link>
      {!isDisabled && (
        <>
          <Box
            component="ul"
            sx={(theme: Theme) => ({
              mx: 'auto',
              p: 0,
              pr: '53px',
              '> *': { mr: 6 },
              [theme.breakpoints.down('lg')]: {
                display: 'none',
              },
            })}
          >
            {items.map((entry, index) => (
              <NavigationItem
                to={entry.url}
                isExternal={entry.isExternal}
                count={entry.count}
                isSelected={activeIndex === index}
                key={index}
              >
                {entry.label}
              </NavigationItem>
            ))}
          </Box>
          {itemForm && (
            <Button
              to={itemForm.url}
              variant="expanded"
              color="primary"
              sx={(theme: Theme) => ({
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                [theme.breakpoints.down('lg')]: {
                  display: 'none',
                },
              })}
            >
              {itemForm.label}
            </Button>
          )}
          <Box
            sx={(theme: Theme) => ({
              pl: 8,
              mr: -2,
              ml: 'auto',
              [theme.breakpoints.up('lg')]: {
                display: 'none',
              },
            })}
          >
            <MenuToggle onClick={onToggle} isToggled={isOpen} />
          </Box>
        </>
      )}
      {isDisabled && (
        <Box
          sx={{
            pl: 8,
            mr: -2,
            ml: 'auto',
          }}
        >
          <MenuToggle onClick={() => navigate('/')} isToggled />
        </Box>
      )}
    </Container>
  )
}
