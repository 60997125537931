import React, { ReactElement } from 'react'
import { HistoryLocation } from 'reach__router'
import { Breakpoint } from '@mui/material'

import {
  getAssetProp,
  getGlobalProp,
  getGlobalPropList,
  getRichTextProp,
} from '@utils/props'

import Facts, { FactsProps } from './Facts'
import Stage, { StageProps } from './Stage'
import Accordion, { AccordionsProps } from './Accordions'
import Testimonials, { TestimonialsProps } from './Testimonials'
import TeaserImage, { TeaserImageProps } from './TeaserImage'
import TeaserMedia, { TeaserMediaProps } from './TeaserMedia'
import DeepLinks, { DeepLinksProps } from './DeepLinks'
import JobFacts, { JobFactsProps } from './JobFacts'
import TeaserText, { TeaserTextProps } from './TeaserText'
import Faqs, { FaqsProps } from './Faqs'
import useStaticContentful from '@components/site/hooks/contentful/useStaticContentful'
import TeaserJobs, { TeaserJobsProps } from './TeaserJobs'
import ContactForm, { ContactFormProps } from './ContactForm'
import Infos, { InfosProps } from './Infos'
import SocialShareCard, { SocialShareCardProps } from './SocialShareCard'
import { SocialShareItemProps } from './SocialShareCard/SocialShareItem'

interface IPageModuleParser {
  __typename: string
  component: React.ElementType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: (
    mod: any,
    options: {
      sx?: MCDC.Props.IDefault['sx']
      maxWidth?: Breakpoint
      theme?: MCDC.Props.ThemeType
    }
  ) => MCDC.Props.UnionModules
}

export const pageModuleParsers: Array<IPageModuleParser> = [
  {
    __typename: 'ContentfulModuleSocialShareCard',
    component: SocialShareCard,
    props: (mod: SocialShareCardProps): SocialShareCardProps => {
      const { headline, socialShareItem } = mod
      return {
        headline,
        socialShareItems: socialShareItem,
      }
    },
  },
  {
    __typename: 'ContentfulModuleFaqs',
    component: Faqs,
    props: (mod: MCDC.Contentful.IModuleFaqs, { maxWidth, sx }): FaqsProps => {
      const { copy, image, topics, ...rest } = mod
      const { getGlobalFaqs } = useStaticContentful()
      const globalTopics = getGlobalPropList(
        topics
      ) as MCDC.Props.IGlobalTopic[]

      return {
        ...rest,
        copy: getRichTextProp(copy),
        image: getAssetProp(image) as MCDC.Props.IAssetImage,
        options: globalTopics?.map((entry) => ({
          label: entry.title,
          value: entry.identifier || '',
        })),
        items: getGlobalFaqs(globalTopics),
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleContactForm',
    component: ContactForm,
    props: (
      mod: MCDC.Contentful.IModuleContactForm,
      { sx }
    ): ContactFormProps => {
      const { topics, linkTo, ...rest } = mod
      const globalTopics = getGlobalPropList(
        topics
      ) as MCDC.Props.IGlobalTopic[]

      return {
        ...rest,
        options: globalTopics?.map((entry) => ({
          label: entry.title,
          value: entry.identifier || '',
        })),
        linkTo: linkTo?.fields?.linkTo,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaserJobs',
    component: TeaserJobs,
    props: (
      mod: MCDC.Contentful.IModuleTeaserJobs,
      { sx }
    ): TeaserJobsProps => {
      const { copy, image, linkTo, linkToParams, linkToLabel, ...rest } = mod
      const linkToSearchParams = !!linkToParams?.internal.content
        ? new URLSearchParams(
            JSON.parse(linkToParams.internal.content) || {}
          ).toString()
        : ''
      const linkToUrl = !!linkTo
        ? `${linkTo?.fields?.linkTo.url}${
            !!linkToSearchParams ? '?' : ''
          }${linkToSearchParams}`
        : '#'
      return {
        ...rest,
        copy: getRichTextProp(copy),
        image: getAssetProp(image) as MCDC.Props.IAssetImage,
        linkTo: {
          label: linkToLabel,
          url: linkToUrl,
          isExternal: linkTo?.fields?.linkTo.isExternal || false,
        },
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaserImage',
    component: TeaserImage,
    props: (
      mod: MCDC.Contentful.IModuleTeaserImage,
      { sx }
    ): TeaserImageProps => {
      const { copy, image, linkTo, ...rest } = mod
      return {
        ...rest,
        copy: getRichTextProp(copy),
        image: getAssetProp(image) as MCDC.Props.IAssetImage,
        linkTo: linkTo?.fields?.linkTo,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaserText',
    component: TeaserText,
    props: (
      mod: MCDC.Contentful.IModuleTeaserText,
      { sx }
    ): TeaserTextProps => {
      const { copy, linkTo, linkToLabel, alignedCenter, ...rest } = mod
      return {
        ...rest,
        copy: getRichTextProp(copy),
        linkTo: linkTo?.fields
          ? {
              ...linkTo.fields.linkTo,
              label: linkToLabel || linkTo.fields.linkTo.label,
            }
          : undefined,
        sx,
        maxWidth: 'sm',
        alignment: alignedCenter ? 'center' : undefined,
      }
    },
  },
  {
    __typename: 'ContentfulModuleFacts',
    component: Facts,
    props: (
      mod: MCDC.Contentful.IModuleFacts,
      { theme, maxWidth, sx }
    ): FactsProps => {
      const { facts, ...rest } = mod
      return {
        ...rest,
        facts: facts?.facts.split('\n'),
        maxWidth,
        theme: theme || 'light',
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleStage',
    component: Stage,
    props: (
      mod: MCDC.Contentful.IModuleStage,
      { maxWidth, sx }
    ): StageProps => {
      const { background, ...rest } = mod
      return {
        ...rest,
        background: getGlobalProp(background) as
          | MCDC.Props.IGlobalImage
          | MCDC.Props.IGlobalVideo,
        maxWidth,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTestimonials',
    component: Testimonials,
    props: (
      mod: MCDC.Contentful.IModuleTestimonials,
      { maxWidth, sx }
    ): TestimonialsProps => {
      const { entries, ...rest } = mod
      return {
        ...rest,
        items: getGlobalPropList(entries) as MCDC.Props.IGlobalTestimonial[],
        maxWidth,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleDeepLinks',
    component: DeepLinks,
    props: (
      mod: MCDC.Contentful.IModuleDeepLinks,
      { maxWidth, sx }
    ): DeepLinksProps => {
      const { entries, ...rest } = mod
      return {
        ...rest,
        items: getGlobalPropList(entries) as MCDC.Props.IGlobalDeepLink[],
        maxWidth,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleFaq',
    component: Accordion,
    props: (
      mod: MCDC.Contentful.IModuleFaq,
      { maxWidth, sx }
    ): AccordionsProps => {
      const { entries, headline, ...rest } = mod

      return {
        ...rest,
        headline,
        headlineSize: 'h4',
        items: (getGlobalPropList(entries) as MCDC.Props.IGlobalFaq[]).map(
          (entry) => ({ title: entry.question, content: entry.answer })
        ),
        maxWidth,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleInfos',
    component: Infos,
    props: (mod: MCDC.Contentful.IModuleInfos, { sx }): InfosProps => {
      const { entries, copy, linkTo, ...rest } = mod
      return {
        ...rest,
        copy: getRichTextProp(copy),
        linkTo: linkTo?.fields?.linkTo,
        items: getGlobalPropList(entries) as MCDC.Props.IGlobalInfo[],
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleJobFacts',
    component: JobFacts,
    props: (
      mod: MCDC.Contentful.IModuleJobFacts,
      { maxWidth, sx }
    ): JobFactsProps => {
      const { entries, ...rest } = mod
      return {
        ...rest,
        items: getGlobalPropList(entries) as MCDC.Props.IGlobalJobFact[],
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaserMedia',
    component: TeaserMedia,
    props: (
      mod: MCDC.Contentful.IModuleTeaserMedia,
      { maxWidth, sx }
    ): TeaserMediaProps => {
      const { entries, ...rest } = mod
      return {
        ...rest,
        items: getGlobalPropList(entries) as MCDC.Props.IGlobalMediaTeaser[],
        sx,
      }
    },
  },
]

type PageModuleProps = MCDC.Props.IDefault & {
  moduleProps: MCDC.Contentful.UnionsModules & { __typename: string }
  location?: HistoryLocation
  theme?: MCDC.Props.ThemeType
  maxWidth?: Breakpoint
}

export function PageModule({
  moduleProps,
  theme,
  maxWidth,
  sx,
}: PageModuleProps): ReactElement | null {
  const Parser = pageModuleParsers.find((moduleParser) => {
    return moduleProps.__typename === moduleParser.__typename
  })

  if (!Parser) {
    console.log('Module not defined: ', moduleProps)
    return null
  }

  return React.createElement(
    Parser.component,
    Parser.props(moduleProps, { sx, maxWidth, theme })
  )
}

type PageModulesProps = MCDC.Props.IDefault & {
  location: HistoryLocation
  modules?: Array<MCDC.Contentful.UnionsModules>
}

export function PageModules({
  className,
  location,
  modules,
}: PageModulesProps): ReactElement {
  return (
    <>
      {modules &&
        modules.map((mod, index) => (
          <PageModule
            key={`${mod.id}${index}`}
            className={className}
            location={location}
            moduleProps={mod}
          />
        ))}
    </>
  )
}
