import Breakpoints from '@config/theme/definitions/breakpoints'
import { getImage, withArtDirection } from 'gatsby-plugin-image'
import { PlaceholderProps } from 'gatsby-plugin-image/dist/src/components/placeholder'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { ReactNode } from 'react'

import { richTextOptionsBasic } from './richtext'

export const getAssetProp = (
  value:
    | MCDC.Contentful.IAsset
    | MCDC.Contentful.IAssetImage
    | MCDC.Contentful.IAssetSvg
    | undefined
):
  | MCDC.Props.IAssetImage
  | MCDC.Props.IAssetSvg
  | MCDC.Props.IAssetVideo
  | undefined => {
  if (!value || !value.file || !value.localFile) return undefined

  const isImage = value.file.contentType.startsWith('image')
  const asset = {
    title: value.title,
    description: value.description,
    file: { ...value.file, url: value.localFile.publicURL },
  }
  if (isImage && (value as MCDC.Contentful.IAssetSvg).localFile.svg) {
    return {
      ...asset,
      content: (value as MCDC.Contentful.IAssetSvg).localFile.svg.content,
      originalContent: (value as MCDC.Contentful.IAssetSvg).localFile.svg
        .originalContent,
    }
  }

  return isImage &&
    (value as MCDC.Contentful.IAssetImage).localFile.childImageSharp
      ?.gatsbyImageData
    ? {
        ...asset,
        data: getImage(
          (value as MCDC.Contentful.IAssetImage).localFile.childImageSharp
            .gatsbyImageData
        ),
      }
    : asset
}

export const getRichTextProp = (
  value: MCDC.Contentful.BasicRichText | undefined
): ReactNode => {
  return value ? renderRichText(value, richTextOptionsBasic) : undefined
}

export const getGlobalProp = (
  value: MCDC.Contentful.UnionGlobals | undefined
): MCDC.Props.UnionGlobals | undefined => {
  if (!value) return undefined
  switch (value.__typename) {
    case 'ContentfulGlobalTopic': {
      const { id, node_locale, identifier, title } =
        value as MCDC.Contentful.IGlobalTopic
      return {
        id,
        node_locale,
        title,
        identifier,
      } as MCDC.Props.IGlobalTopic
    }
    case 'ContentfulGlobalImage': {
      const { theme, large, medium, small } =
        value as MCDC.Contentful.IGlobalImage

      const imageLG = getAssetProp(large) as MCDC.Props.IAssetImage
      const dataMD =
        medium && getImage(medium.localFile?.childImageSharp.gatsbyImageData)
      const dataSM =
        small && getImage(small.localFile?.childImageSharp.gatsbyImageData)

      if (!imageLG) return

      const placeholder: Pick<PlaceholderProps, 'sources' | 'fallback'> = {
        fallback: imageLG.data?.placeholder?.fallback || '',
        sources: [
          dataSM &&
            dataSM.placeholder?.fallback && {
              media: `(max-width: ${(Breakpoints.values?.md || 0) - 1}px)`,
              srcSet: dataSM.placeholder.fallback,
            },
          dataMD &&
            dataMD.placeholder?.fallback && {
              media: `(max-width: ${(Breakpoints.values?.lg || 0) - 1}px)`,
              srcSet: dataMD.placeholder.fallback,
            },
          imageLG.data?.placeholder?.fallback && {
            media: `(min-width: ${Breakpoints.values?.lg || 0}px)`,
            srcSet: imageLG.data.placeholder.fallback,
          },
        ].filter((entry) => !!entry) as any,
      }

      const withArtDirectionData = withArtDirection(
        imageLG.data,
        [
          dataSM && {
            media: `(max-width: ${(Breakpoints.values?.md || 0) - 1}px)`,
            image: dataSM,
          },
          dataMD && {
            media: `(max-width: ${(Breakpoints.values?.lg || 0) - 1}px)`,
            image: dataMD,
          },
        ].filter((entry) => !!entry) as any
      )

      withArtDirectionData.placeholder = placeholder

      return {
        ...imageLG,
        theme,
        data: withArtDirectionData,
        ratios: {
          sm: dataSM ? dataSM.height / dataSM.width : undefined,
          md: dataMD ? dataMD.height / dataMD.width : undefined,
          lg: imageLG ? imageLG.data.height / imageLG.data.width : undefined,
        },
      } as MCDC.Props.IGlobalImage
    }
    case 'ContentfulGlobalVideo': {
      const { id, node_locale, playlist, playlistMobile, image } =
        value as MCDC.Contentful.IGlobalVideo
      return {
        id,
        node_locale,
        playlist,
        playlistMobile,
        image: getGlobalProp(image) as MCDC.Props.IGlobalImage,
      } as MCDC.Props.IGlobalVideo
    }
    case 'ContentfulGlobalTestimonial': {
      const { id, node_locale, author, copy, image } =
        value as MCDC.Contentful.IGlobalTestimonial
      return {
        id,
        node_locale,
        author,
        copy: getRichTextProp(copy),
        image: getAssetProp(image) as MCDC.Props.IAssetImage,
      } as MCDC.Props.IGlobalTestimonial
    }
    case 'ContentfulGlobalFaq': {
      const { id, node_locale, answer, question, topics } =
        value as MCDC.Contentful.IGlobalFaq
      return {
        id,
        node_locale,
        answer: getRichTextProp(answer),
        question,
        topics: getGlobalPropList(topics) as MCDC.Props.IGlobalTopic[],
      } as unknown as MCDC.Props.IGlobalFaq
    }
    case 'ContentfulGlobalDeepLink': {
      const { id, node_locale, title, image, linkTo, linkToParams } =
        value as MCDC.Contentful.IGlobalDeepLink

      const linkToSearchParams =
        !!linkTo && !!linkToParams?.internal.content
          ? new URLSearchParams(
              JSON.parse(linkToParams.internal.content) || {}
            ).toString()
          : ''
      const linkToUrl = !!linkTo
        ? `${linkTo?.fields?.linkTo.url}?${linkToSearchParams}`
        : ''

      return {
        id,
        node_locale,
        image: getAssetProp(image) as MCDC.Props.IAssetImage,
        linkTo: linkTo?.fields
          ? {
              ...linkTo?.fields.linkTo,
              url: linkToUrl,
              label: title,
            }
          : undefined,
        linkToParams,
      } as MCDC.Props.IGlobalDeepLink
    }
    case 'ContentfulGlobalInfo': {
      const { id, node_locale, title, description, image, linkTo } =
        value as MCDC.Contentful.IGlobalInfo
      return {
        id,
        node_locale,
        title,
        description: getRichTextProp(description),
        image: getAssetProp(image) as MCDC.Props.IAssetImage,
        linkTo: linkTo?.fields?.linkTo,
      } as MCDC.Props.IGlobalDeepLink
    }
    case 'ContentfulGlobalJobFact': {
      const { id, node_locale, title, description, icon } =
        value as MCDC.Contentful.IGlobalJobFact
      return {
        id,
        node_locale,
        title,
        description: description?.description || '',
        icon: getAssetProp(icon) as MCDC.Props.IAssetSvg,
      } as MCDC.Props.IGlobalJobFact
    }
    case 'ContentfulGlobalMediaTeaser': {
      const {
        id,
        node_locale,
        layout,
        headline,
        subline,
        copy,
        image,
        linkTo,
        linkToLabel,
      } = value as MCDC.Contentful.IGlobalMediaTeaser
      return {
        id,
        node_locale,
        layout,
        headline,
        subline,
        copy: getRichTextProp(copy),
        image: getAssetProp(image) as MCDC.Props.IAssetImage,
        linkTo: linkTo?.fields?.linkTo
          ? {
              ...linkTo?.fields.linkTo,
              label: linkToLabel || linkTo?.fields.linkTo.label,
            }
          : undefined,
        linkToLabel,
      } as MCDC.Props.IGlobalMediaTeaser
    }
  }

  return undefined
}

export const getGlobalPropList = (
  entries: MCDC.Contentful.UnionGlobals[] | undefined
): MCDC.Props.UnionGlobals[] | undefined =>
  entries
    ? (entries
        .map((entry) => getGlobalProp(entry))
        .filter((entry) => !!entry) as MCDC.Props.UnionGlobals[])
    : undefined

export const getAssetPropList = (
  entries:
    | (
        | MCDC.Contentful.IAsset
        | MCDC.Contentful.IAssetImage
        | MCDC.Contentful.IAssetSvg
      )[]
    | undefined
):
  | (MCDC.Props.IAssetImage | MCDC.Props.IAssetSvg | MCDC.Props.IAssetVideo)[]
  | undefined =>
  entries
    ? (entries
        .map((entry) => getAssetProp(entry))
        .filter((entry) => !!entry) as (
        | MCDC.Props.IAssetImage
        | MCDC.Props.IAssetSvg
        | MCDC.Props.IAssetVideo
      )[])
    : undefined

export const isVideoFile = (file?: MCDC.Contentful.IFile): boolean => {
  return file?.contentType?.startsWith('video') || false
}
