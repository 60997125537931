/* eslint-disable @typescript-eslint/no-explicit-any */

import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'react-intl'

const useContentfulFormSteps = (): {
  formStepPages: MCDC.Contentful.IPageFormStep[]
  formPage: MCDC.Contentful.IPageGeneric
  formSuccessPage: MCDC.Contentful.IPageGeneric
} => {
  const { locale } = useIntl()
  const { data, formPages, formSuccessPages } = useStaticQuery(
    graphql`
      query {
        data: allLocalPageFormStep {
          nodes {
            ...PageFormStepFragment
          }
        }
        formPages: allContentfulPageGeneric(
          filter: { metaType: { eq: "applicationForm" } }
        ) {
          nodes {
            ...PageGenericFragment
          }
        }
        formSuccessPages: allContentfulPageGeneric(
          filter: { metaType: { eq: "applicationFormSuccess" } }
        ) {
          nodes {
            ...PageGenericFragment
          }
        }
      }
    `
  )

  return {
    formStepPages: data.nodes.filter(
      (entry: MCDC.Contentful.IPageFormStep) => entry.node_locale === locale
    ) as MCDC.Contentful.IPageFormStep[],
    formPage: formPages.nodes.find(
      (entry: MCDC.Contentful.IPageGeneric) => entry.node_locale === locale
    ) as MCDC.Contentful.IPageGeneric,
    formSuccessPage: formSuccessPages.nodes
      .reverse()
      .find(
        (entry: MCDC.Contentful.IPageGeneric) => entry.node_locale === locale
      ) as MCDC.Contentful.IPageGeneric,
  }
}
export default useContentfulFormSteps
