import { Components, Theme } from '@mui/material'

export const MuiFormControlOverrides = (
  theme: Theme
): Components['MuiFormControl'] => ({
  styleOverrides: {
    root: {
      width: '100%',
      '.MuiInputLabel-root + .MuiInputBase-root .MuiInputBase-input': {
        paddingTop: theme.spacing(3),
      },
    },
  },
})

export const MuiInputBaseOverrides = (
  theme: Theme
): Components['MuiInputBase'] => ({
  styleOverrides: {
    root: {
      height: '56px',
      border: `2px solid transparent`,
      transition: 'border-color 250ms ease !important',
      ':hover:not(.Mui-disabled):not(.Mui-focused)': {
        backgroundColor: `${theme.palette.common.white} !important`,
        borderColor: theme.palette.grey[200],
      },
      '.MuiSelect-icon': {
        color: theme.palette.primary.dark,
      },
      '.MuiInputAdornment-root': {
        color: theme.palette.text.primary,
        fontWeight: 100,
      },
      '&.MuiInputBase-colorSuccess': {
        borderColor: theme.palette.success.main,
        '.MuiInputAdornment-root': {
          color: theme.palette.success.main,
        },
        '.MuiSelect-icon': {
          color: theme.palette.success.main,
        },
      },

      '&.Mui-disabled': {
        color: theme.palette.grey[300],
        '.MuiSelect-select': {
          WebkitTextFillColor: theme.palette.grey[400],
        },
      },

      '&.MuiInput-underline': {
        border: 'none',
        background: 'transparent',
        '&::before': {
          borderBottom: '0px !important',
        },
        '&::after': {
          borderBottom: 'transparent !important',
        },
      },
      '&.Mui-focused': {
        backgroundColor: `${theme.palette.common.white} !important`,
        borderColor: theme.palette.primary.main,
      },
    },
    input: {
      backgroundColor: 'transparent !important',
      '&.MuiSelect-outlined': {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
      },
      '&.MuiSelect-standard': {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      '&[type="search"]::-webkit-search-decoration,&[type="search"]::-webkit-search-cancel-button, &[type="search"]::-webkit-search-results-button, &[type="search"]::-webkit-search-results-decoration':
        {
          appearance: 'none',
        },
    },

    error: {
      borderColor: theme.palette.error.main,
      '.MuiInputAdornment-root': {
        color: theme.palette.error.main,
      },
      '.MuiSelect-icon': {
        color: theme.palette.error.main,
      },
    },
    multiline: {
      height: 'auto',
      '.MuiInputAdornment-root': {
        alignSelf: 'flex-start',
      },
    },
  },
})

export const MuiRadioOverrides = (theme: Theme): Components['MuiRadio'] => ({
  styleOverrides: {
    root: {
      color: theme.palette.primary.main,
      '&.Mui-error': {
        color: theme.palette.error.main,
      },
    },
  },
})

export const MuiCheckboxOverrides = (
  theme: Theme
): Components['MuiCheckbox'] => ({
  styleOverrides: {
    root: {
      color: theme.palette.primary.main,
      '&.Mui-error': {
        color: theme.palette.error.main,
      },
    },
    checked: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      fill: 'none',
    },
  },
})

export const MuiFilledInputOverrides = (
  theme: Theme
): Components['MuiFilledInput'] => ({
  defaultProps: {
    disableUnderline: true,
  },
  styleOverrides: {
    root: {
      borderRadius: '8px',
      backgroundColor: theme.palette.common.white,
    },
    input: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
})

export const MuiOutlinedInputOverrides = (
  theme: Theme
): Components['MuiOutlinedInput'] => ({
  defaultProps: {},
  styleOverrides: {
    root: {
      borderRadius: '8px',
      backgroundColor: theme.palette.common.white,
      // height: '56px',
    },
    notchedOutline: {
      display: 'none',
    },
    input: {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    },
  },
})

export const MuiInputLabelOverrides = (
  theme: Theme
): Components['MuiInputLabel'] => ({
  styleOverrides: {
    root: {
      color: theme.palette.grey['400'],
      '&.Mui-error, &.Mui-focused': {
        color: theme.palette.grey['400'],
      },
    },
    asterisk: {
      color: theme.palette.grey['400'],
      '&.Mui-error, &.Mui-focused': {
        color: theme.palette.grey['400'],
      },
    },
  },
})

export const MuiFormHelperTextOverrides = (
  theme: Theme
): Components['MuiFormHelperText'] => ({
  styleOverrides: {
    root: {
      '.MuiInputBase-colorSuccess + &': {
        color: theme.palette.success.main,
      },

      '.MuiInputBase-root.Mui-error + &': {
        color: theme.palette.error.main,
      },
    },
  },
})
