import React, { ReactElement, useState } from 'react'
import { Box, Grid, IconButton } from '@mui/material'

import Headline from '@components/core/text/Headline'

import DatePicker from '../../fields/DatePicker'
import TextInput from '../../fields/TextInput'
import Copy from '@components/core/text/Copy'
import Icon from '@components/core/media/Icon'

export type EmploymentGroupProps = MCDC.Props.IDefault & {
  id: string
  defaultValue?: MCDC.Props.IEmployment
  onDelete: (value: MCDC.Props.IEmployment) => void
  onChange: (value: MCDC.Props.IEmployment) => void
  rowSpacing?: number
  columnSpacing?: number
  isError?: boolean
  isRequired?: boolean
  labels?: {
    title?: string
    date?: string
    dateFrom?: string
    dateTo?: string
    company?: string
    position?: string
  }
}

export default function EmploymentGroup({
  id,
  labels,
  defaultValue,
  isError,
  isRequired,
  onChange,
  onDelete,
  rowSpacing = 4,
  columnSpacing = 4,
  sx,
}: EmploymentGroupProps): ReactElement {
  const [value, setValue] = useState<MCDC.Props.IEmployment>(defaultValue || {})

  function changeHandler(value: MCDC.Props.IEmployment) {
    setValue(value)
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <Box sx={sx}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {labels?.title && (
          <Headline variant="h5" sx={{ mb: 6 }}>
            {labels.title}
          </Headline>
        )}
        {onDelete && (
          <IconButton onClick={() => onDelete(value)}>
            <Icon variant="delete" />
          </IconButton>
        )}
      </Box>
      <Grid container rowSpacing={rowSpacing} columnSpacing={columnSpacing}>
        {labels?.date && (
          <Grid item xs={12}>
            <Copy>{labels?.date}</Copy>
          </Grid>
        )}
        <Grid item xs={6} md={3}>
          <DatePicker
            id={`${id}-from`}
            label={labels?.dateFrom}
            onChange={(fieldValue) =>
              changeHandler({ ...(value || {}), period_from: fieldValue || '' })
            }
            defaultValue={defaultValue?.period_from}
            isError={isError && !value.period_from}
            isRequired={isRequired}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <DatePicker
            id={`${id}-to`}
            label={labels?.dateTo}
            onChange={(fieldValue) =>
              changeHandler({ ...(value || {}), period_to: fieldValue || '' })
            }
            defaultValue={defaultValue?.period_to}
            isError={isError && !value.period_to}
            isRequired={isRequired}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id={`${id}-company`}
            placeholder={labels?.company}
            defaultValue={defaultValue?.company}
            onChange={(fieldValue) =>
              changeHandler({ ...(value || {}), company: fieldValue })
            }
            isError={isError && !value.company}
            isRequired={isRequired}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id={`${id}-position`}
            placeholder={labels?.position}
            defaultValue={defaultValue?.position}
            onChange={(fieldValue) =>
              changeHandler({ ...(value || {}), position: fieldValue })
            }
            isError={isError && !value.position}
            isRequired={isRequired}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
