import React, { ReactElement } from 'react'
import { Box, Theme } from '@mui/material'

import SocialShareItem from './SocialShareItem'
import Slider from '@components/core/container/Slider'
import Headline from '@components/core/text/Headline'
import Section from '@components/core/container/Section'

export type SocialShareCardProps = {
  headline: string
  socialShareItems: Array<{
    icon: string
    link: string
    embedCode: string
    label: string
  }>
  sx?: MCDC.Props.IDefault['sx']
}

export default function SocialShareCard(
  props: SocialShareCardProps
): ReactElement {
  const { headline, socialShareItems, sx } = props

  return (
    <Section theme="medium">
      <Box
        sx={(theme: Theme) => ({
          p: 12,
          [theme.breakpoints.down('md')]: {
            p: 0,
          },
        })}
      >
        <Headline variant="h2" sx={{ pb: 24 }}>
          {headline}
        </Headline>
        <Box sx={{ position: 'relative' }}>
          <Slider
            theme="light"
            arrows="ontop"
            withOverflow={{ sm: true, md: false, lg: false }}
            breakpoints={{
              sm: { slidesPerView: 1 },
              md: { slidesPerView: 2 },
              lg: { slidesPerView: 2.5 },
            }}
          >
            {socialShareItems.map((item) => (
              <SocialShareItem key={Math.random()} {...item} />
            ))}
          </Slider>
        </Box>
      </Box>
    </Section>
  )
}
