import React, { useEffect, useState } from 'react'
import { useSwiper } from 'swiper/react'

import { alpha, Box, IconButton, Theme } from '@mui/material'

import Icon from '@components/core/media/Icon'
import muiTheme from '@config/theme'

function getThemeStyles(muiTheme: Theme, theme?: MCDC.Props.ThemeType) {
  switch (theme) {
    case 'light':
    case 'medium':
      return {
        color: muiTheme.palette.text.inverted,
        backgroundColor: alpha(
          muiTheme.palette.background.primary as string,
          0.5
        ),
      }
    case 'dark':
    case 'primary':
    case 'secondary':
      return {
        color: muiTheme.palette.text.primary,
        backgroundColor: muiTheme.palette.common.white,
      }
    default:
      return {}
  }
}

export type SliderUiProps = {
  activeIndex?: number
  total?: number
  loop?: boolean
  theme?: MCDC.Props.ThemeType
  sx?: MCDC.Props.IDefault['sx']
}

export type SliderUiOnTopProps = {
  activeIndex?: number
  total?: number
  loop?: boolean
  theme?: MCDC.Props.ThemeType
  sx?: MCDC.Props.IDefault['sx']
  onClickLeft?: () => void
  onClickRight?: () => void
  totalSlides?: number | undefined
  filterChanged?: boolean
  resetFilterChanged?: () => void
  isMobile?: boolean
}

export const SliderUi = ({ sx, loop, theme }: SliderUiProps) => {
  const swiper = useSwiper()
  const themeStyles = getThemeStyles(muiTheme, theme)
  if (swiper.pagination?.bullets.length <= 1) return null
  return (
    <Box
      sx={[
        {
          display: 'flex',
          position: 'absolute',
          direction: 'rtl',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: 1,
          pointerEvents: 'none',
          '.MuiIconButton-root': {
            color: themeStyles.color || 'text.inverted',
            border: 'none',
            backgroundColor: themeStyles.backgroundColor
              ? themeStyles.backgroundColor
              : undefined,
          },
          '.MuiIconButton-root:hover': {
            color: themeStyles.color || 'text.inverted',
            backgroundColor: themeStyles.backgroundColor
              ? alpha(themeStyles.backgroundColor as string, 0.5)
              : undefined,
          },
        },
        sx,
      ]}
    >
      <IconButton
        onClick={() => swiper.slideNext()}
        disabled={!loop && swiper.progress >= 1}
        sx={{
          pointerEvents: 'auto',
          '&.Mui-disabled': {
            backgroundColor: themeStyles.backgroundColor
              ? alpha(themeStyles.backgroundColor as string, 0.5)
              : undefined,
          },
        }}
      >
        <Icon variant="chevron_right" />
      </IconButton>
      <IconButton
        onClick={() => swiper.slidePrev()}
        disabled={!loop && swiper.progress <= 0}
        sx={{
          pointerEvents: 'auto',
          '&.Mui-disabled': {
            backgroundColor: themeStyles.backgroundColor
              ? alpha(themeStyles.backgroundColor as string, 0.5)
              : undefined,
          },
        }}
      >
        <Icon variant="chevron_left" />
      </IconButton>
    </Box>
  )
}
